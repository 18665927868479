
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";
export const ManagerReport = ({managerBudget}: any) => {

  return (
    <Box >
      <Card sx={{ p: 3, boxShadow: 3, borderRadius: 3, maxWidth: 500 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", textAlign: "center" }}>Budget Overview</Typography>
          <Grid container spacing={1}>
            <Grid item xs={6}><Typography variant="body1">Number of Employees:</Typography></Grid>
            <Grid item xs={6} textAlign="right"><Typography variant="body1"><strong>{managerBudget.numberOfEmployees}</strong></Typography></Grid>
            <Grid item xs={6}><Typography variant="body1">Total Budget:</Typography></Grid>
            <Grid item xs={6} textAlign="right"><Typography variant="body1"><strong>ILS {managerBudget.totalBudget}</strong></Typography></Grid>
            <Grid item xs={6}><Typography variant="body1">Used Budget:</Typography></Grid>
            <Grid item xs={6} textAlign="right"><Typography variant="body1"><strong>ILS {managerBudget.usedBudget}</strong></Typography></Grid>
            <Grid item xs={6}><Typography variant="body1">Remaining Budget:</Typography></Grid>
            <Grid item xs={6} textAlign="right"><Typography variant="body1" sx={{ color: managerBudget.remainingBudget > 0 ? "green" : "red", fontWeight: "bold" }}>ILS {managerBudget.remainingBudget}</Typography></Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
export default ManagerReport;
