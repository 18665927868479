import { SET_EMPLOYEES} from "../actions/employees/types";


const initialState = {
  
};

const employeesReducer = (state = initialState, action: any): any => {
  switch (action.type) {
  case SET_EMPLOYEES:
    return { ...state, employees: action.payload.employees };
  default:
    return state;
  }
};

export { initialState, employeesReducer };