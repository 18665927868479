import { useRef } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { News } from "../../types/types";
import { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import MuiTextField from "@mui/material/TextField";
import PortalApis from "../../apis/PortalApi";
import theme from "../../Theme";
import Editor from "../Editor/Editor";

interface NewsDialogProps {
  setOpen: (data: { news: News | null; open: boolean }) => any;
  open: boolean;
  newsItem: News | null;
  setNews: any;
  news: News[];
}

const NewsFormDialog = (props: NewsDialogProps) => {
  const { setOpen, open, newsItem, setNews, news } = props;
  const [error, setError] = useState("");
  const editorRef: any = useRef(null);
  const validationSchema = Yup.object().shape({
    title: Yup.string().nullable().required("Required"),
    deadline_date: Yup.string().nullable().required("Required"),
  });

  const submitForm = (data: any) => {
    if (newsItem && newsItem.id) {
      PortalApis.editNewsItem(
        newsItem.id,
        data.title,
        data.content,
        data.image,
        data.deadline_date,
        data.send_announcement,
        data.summary
      ).then((newsItem: News) => {
        setNews((oldNews: News[]) => [
          ...oldNews.filter((fl) => fl.id != newsItem.id),
          newsItem,
        ]);
        setOpen({ open: false, news: null });
      });
    } else {
      if (checkNewsUnique(data.title)) {
        PortalApis.addNewsItem(
          data.title,
          data.content,
          data.image,
          data.deadline_date,
          data.send_announcement,
          data.summary
        ).then((item: News) => {
          setNews((oldNews: News[]) => [...oldNews, item]);
          setOpen({ open: false, news: null });
        });
      } else {
        setError("News Title is Duplicated !");
      }
    }
  };

  const checkNewsUnique = (value: string) => {
    let unique = true;
    news.forEach((newsItem: News) => {
      if (newsItem.title === value) {
        unique = false;
      }
    });
    return unique;
  };

  const minDays = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today.toISOString().split("T")[0];
  };

  return (
    (open && (
      <Dialog open={open} onClose={() => setOpen({ open: false, news: null })}>
        <DialogTitle>
          {newsItem ? `Editing News: ${newsItem.title}` : "Add New Announcement"}
        </DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <Formik
            initialValues={{
              title: newsItem && newsItem.title,
              content: newsItem && newsItem.content,
              image: newsItem && newsItem.image,
              send_announcement: newsItem && newsItem.send_announcement || false,
              deadline_date: newsItem && newsItem.deadline_date,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, formikHelpers) => {
              submitForm(values);
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Form>
                  <Box
                    margin={2}
                    alignContent={"center"}
                    flexDirection={"column"}
                  >
                    <Field
                      component={MuiTextField}
                      fullWidth
                      defaultValue={newsItem && newsItem.title}
                      label="Title"
                      name={"title"}
                      error={(touched["title"] && !!errors["title"]) || false}
                      onChange={(e: any) =>
                        setFieldValue("title", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="title"
                      render={(msg: string) => <div>{msg}</div>}
                    />
                  </Box>
                  <Box
                    margin={2}
                    alignContent={"center"}
                    flexDirection={"column"}
                  >
                    <Editor
                      setEditor={(editor: any) => editorRef.current = editor}
                      defaultValue={newsItem?.content}
                      onChange={(content: any) => {
                        setFieldValue("summary", editorRef.current?.editingArea?.innerText);
                        setFieldValue("content", content);
                      }}
                    />
                  </Box>
                  <Box
                    margin={2}
                    alignContent={"center"}
                    flexDirection={"column"}
                  >
                    <div
                      style={{
                        marginBottom: "10px",
                        fontSize: "14px",
                        color: `${theme.palette.primary.main}`,
                      }}
                    >
                      Select Image
                    </div>
                    <Field
                      type={"file"}
                      label="Image"
                      name={"image"}
                      id={"image"}
                      accept="image/*"
                      value={undefined}
                      onChange={(e: any) => {
                        setFieldValue("image", e.currentTarget.files[0]);
                      }}
                    />
                    <ErrorMessage
                      name="image"
                      render={(msg: string) => <div>{msg}</div>}
                    />
                  </Box>
                  <Box
                    margin={2}
                    alignContent={"center"}
                    flexDirection={"column"}
                  >
                    <div
                      style={{
                        marginBottom: "10px",
                        fontSize: "14px",
                        color: `${theme.palette.primary.main}`,
                      }}
                    >
                      Select Deadline Date
                    </div>
                    <Field
                      type={"date"}
                      label="Date"
                      min={minDays()}
                      name={"deadline_date"}
                      id={"date"}
                      value={undefined}
                      onChange={(e: any) => {
                        setFieldValue("deadline_date", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      name="deadline_date"
                      render={(msg: string) => <div>{msg}</div>}
                    />
                  </Box>
                  <Box
                    margin={2}
                    alignContent={"center"}
                    flexDirection={"column"}
                  >
                    <label>
                      <Field type="checkbox" defaultValue={false} name="send_announcement"/>
                      Send Announcement To Office Email
                    </label>
                  </Box>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Form>
              </LocalizationProvider>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    )) || <div></div>
  );
};

export default NewsFormDialog;
