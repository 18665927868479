import { Employee } from "../../../types/types";
import { SET_EMPLOYEES} from "./types";



export const setEmployeesAction = (employees: Employee[]) => (dispatch: any) => {
  dispatch({
    type: SET_EMPLOYEES,
    payload: { employees }
  });
};
