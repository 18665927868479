// Type definitions for the Idea model

export type IdeaType = 
  | "NEW_PRODUCT"
  | "PROBLEM_SOLVE"
  | "PROCESS_IMPROVEMENT"
  | "COST_SAVING"
  | "NEW_OPPORTUNITY"
  | "OTHERS";

export const ideaTypeLabels: Record<IdeaType, string> = {
  NEW_PRODUCT: "New product",
  PROBLEM_SOLVE: "A problem to solve",
  PROCESS_IMPROVEMENT: "Process Improvement",
  COST_SAVING: "Cost Saving",
  NEW_OPPORTUNITY: "New Opportunity",
  OTHERS: "Others"
};

export type ImpactType = 
  | "REVENUE"
  | "EFFICIENCY"
  | "CUSTOMER_SATISFACTION"
  | "OTHER";

export const impactTypeLabels: Record<ImpactType, string> = {
  REVENUE: "Revenue",
  EFFICIENCY: "Efficiency",
  CUSTOMER_SATISFACTION: "Customer Satisfaction",
  OTHER: "Other"
};

export type IdeaStatus =
  | "REQUESTED"
  | "APPROVED"
  | "CANT_PROCEED"


export const ideaStatusLabels: Record<IdeaStatus, string> = {
  REQUESTED: "Requested",
  APPROVED: "Approved",
  CANT_PROCEED: "Can't proceed",
};

export const ideaStatusColors: Record<IdeaStatus, string> = {
  REQUESTED: "info",
  APPROVED: "success",
  CANT_PROCEED: "error",
};

export interface Attachment {
  id: number;
  file: string;
  file_url: string;
  filename: string;
  upload_date: string;
  file_type: string;
  file_size: number;
  file_size_formatted: string;
}

export interface Idea {
  id: number;
  owner: string;
  creation_date: string;
  status: IdeaStatus;
  title: string;
  description: string;
  idea_type: IdeaType;
  potential_impact: ImpactType | null;
  target_audience: string | null;
  estimated_resources: string | null;
  peer_support: string | null;
  time_to_implement: string | null;
  attachments: Attachment[];

}

export interface CreateIdeaDto {
  title: string;
  description: string;
  idea_type: IdeaType;
  potential_impact?: ImpactType | null;
  target_audience?: string | null;
  estimated_resources?: string | null;
  peer_support?: string | null;
  time_to_implement?: string | null;
}

export interface UpdateIdeaStatusDto {
  id: number;
  status: IdeaStatus;
}

export interface UpdateIdeaDto extends Partial<CreateIdeaDto> {
  id: number;
}

export interface FileWithPreview {
  file: File;
  id: string;
  preview?: string;
  name: string;
  size: string;
  type: string;
}

