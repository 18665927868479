import { RecognitionRequest, RecognitionCategory } from "../../../types/types";
import { SET_RECOGNITIONS, SET_RECOGNITIONS_CATEGORIES} from "./types";



export const setRecognitionsAction = (recognitions: RecognitionRequest[]) => (dispatch: any) => {
  dispatch({
    type: SET_RECOGNITIONS,
    payload: { recognitions }
  });
};

export const setRecognitionsCategoriesAction = (recognitionsCategories: RecognitionCategory[]) => (dispatch: any) => {
  dispatch({
    type: SET_RECOGNITIONS_CATEGORIES,
    payload: { recognitionsCategories }
  });
};
