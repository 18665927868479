import { Typography, Link } from "@mui/material";
import { Box } from "@mui/system";
import { Category } from "../../types/types";
import { useCategoriesStyles } from "./categories.style";
import { useNavigate } from "react-router-dom";
import useIcons from "../../hooks/useIcons";
import OperationsCard from "../Home/OperationsCard";
import { RootState } from "../../redux/store";
import {  useSelector } from "react-redux";

interface ParentProps {
  onCategoryClick: (category: Category) => void;
  categories: Category[];
}

export const Categories = (props: ParentProps) => {
  const classes = useCategoriesStyles();
  const navigate = useNavigate();
  const { root, categoryCard, name, icon, desc } = classes;

  const { userDetails } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
  }));

  const onCategoryClick = (category: Category) => {
    props.onCategoryClick(category);
    navigate(`category/${category.id}`);
  };

  return (
    <>
      <Box className={root}>
        { userDetails.is_ideas_reviewer && <OperationsCard />}
        {props.categories?.map((category: Category) => {
          const Icon = category.icon || useIcons("Category");
          return (
            
            <Box
              className={categoryCard}
              key={category.id}
              onClick={() => onCategoryClick(category)}
            >
              {Icon && (
                <div className={icon}>
                  <Icon fontSize="large" />
                </div>
              )}
              <div>
                <Typography className={name}>{category.name}</Typography>
                <Typography className={desc}>{category.description}</Typography>
              </div>
            </Box>
          );
        })}
      </Box>
    </>
  );
};
export default Categories;
