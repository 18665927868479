import {
  Typography,
  Box,
} from "@mui/material";
import { useIdeasStyles } from "./Ideas.style";
import { UpperBar } from "../../components/UpperBar/UpperBar";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

import { Backdrop, CircularProgress } from "@mui/material";
import IdeaForm from "./IdeaForm";
import IdeasDashboard from "./IdeasDashboard";

const Loader = () => (
  <Backdrop open>
    <CircularProgress color="inherit" />
  </Backdrop>
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2, pr: 3, pb: 3, pl: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function IdeasManagementDashboard() {
  const { userDetails } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
  }));

  const [value, setValue] = useState(0); // Default to "New Idea"
  const [loading, setLoading] = useState(false);

  const handleIdeaSubmitSuccess = () => {
    // Change tab to Ideas Dashboard (index 1)
    setValue(1);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      // If "Operations" is selected, keep showing "New Idea" (index 1)
      setValue(1);
    } else {
      setValue(newValue);
    }
  };

  const classes = useIdeasStyles();
  const { root } = classes;

  const [selectedIdeaId, setSelectedIdeaId] = useState<number | null>(null);
  const [showIdeaDetails, setShowIdeaDetails] = useState(false);

  // Handler for viewing idea details
  const handleViewIdea = (ideaId: number) => {
    setSelectedIdeaId(ideaId);
    setShowIdeaDetails(true);
  };

  // Handler for closing idea details view
  const handleCloseIdeaDetails = () => {
    setShowIdeaDetails(false);
    setSelectedIdeaId(null);
  };

  return (
    <Box className={root}>
      <UpperBar bgColor={"secondary"} />

      <div>
        {!loading ? (
          <>
            <Box display="flex" gap={2} mb={2} sx={{ height: "100%", minHeight: "300px" }}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{
                  borderRight: 1,
                  borderColor: "divider",
                  flexShrink: 0,
                  minWidth: "150px",
                }}
              >
                {/* Operations - Not Clickable */}
                <Tab
                  disabled
                  label={
                    <Typography
                      variant="h4"
                      component="h1"
                      fontWeight="medium"
                      sx={{
                        textTransform: "none",
                        color: "#375E99",
                        cursor: "default",
                      }}
                    >
                      Operations
                    </Typography>
                  }
                  sx={{
                    "&.Mui-disabled": {
                      opacity: 1,
                      color: "#375E99",
                    },
                  }}
                  aria-hidden="true"
                />

                {/* New Idea */}
                <Tab label="New Idea" {...a11yProps(0)} />

                {/* Ideas Dashboard */}
                <Tab label="Ideas Dashboard" {...a11yProps(1)} />
              </Tabs>

              {/* Show New Idea content if Operations is selected */}
              <TabPanel value={value === 0 ? 1 : value} index={1}>
                <IdeaForm onSubmitSuccess={handleIdeaSubmitSuccess} />
              </TabPanel>

              <TabPanel value={value} index={2}>
                <IdeasDashboard
                  onViewIdea={handleViewIdea}
                  selectedIdeaId={selectedIdeaId}
                  showIdeaDetails={showIdeaDetails}
                  onCloseIdeaDetails={handleCloseIdeaDetails}
                />
              </TabPanel>
            </Box>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </Box>
  );
}
