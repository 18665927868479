import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Alert,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { useSettingsStyles } from "./Settings.style";
import { useEffect, useState } from "react";
import { Category, Link, News } from "../../types/types";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { NavigateOptions } from "react-router/dist/lib/context";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import PortalApis from "../../apis/PortalApi";
import CategoryFormDialog from "../../components/CategoryFormDialog/CategoryFormDialog";
import LinkFormDialog from "../../components/LinkFormDialog/LinkFormDialog";
import useIcons from "../../hooks/useIcons";
import { trunclateLongText } from "../../utils";
import theme from "../../Theme";
import NewsFormDialog from "../../components/NewsFormDialog/NewsFormDialog";
import { UpperBar } from "../../components/UpperBar/UpperBar";

type Order = "asc" | "desc";

export const Settings = () => {
  const navigate = useNavigate();
  const navigateOptions: NavigateOptions = { relative: "route" };
  const [categories, setCategories] = useState<Category[]>([]);
  const [links, setLinks] = useState<Link[]>([]);
  const [news, setNews] = useState<News[]>([]);
  const [confirmProps, setConfirmProps] = useState<any>({
    open: false,
    data: null,
  });

  const [categoryFromData, setCategoryFromData] = useState<{
    category: Category | null;
    open: boolean;
  }>({ category: null, open: false });
  const [linkFromData, setLinkFromData] = useState<{
    link: Link | null;
    open: boolean;
  }>({ link: null, open: false });
  const [NewsFromData, setNewsFromData] = useState<{
    news: News | null;
    open: boolean;
  }>({ news: null, open: false });
  const [warning, setWarning] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const groupedData: { [x: string]: Link[] } = {};

  const classes = useSettingsStyles();
  const {
    root,
    categoriesBoxWrapper,
    boxHeader,
    widthAuto,
    width15,
    width5,
    width20,
    width25,
    width10,
  } = classes;

  const handleGetCategories = async () => {
    const categories = await PortalApis.getCategories().then(
      (categories) => categories
    );
    if (categories) {
      setCategories(categories);
    }
  };

  const handleGetLinks = async () => {
    //change category id
    const links = await PortalApis.listAllLinks().then((links) => links);
    if (links) {
      setLinks(links);
    }
  };

  const handleGetNews = async () => {
    const news = await PortalApis.getNews().then((news) => news);
    if (news) {
      setNews(news);
    }
  };

  useEffect(() => {
    handleGetCategories();
    handleGetLinks();
    handleGetNews();
  }, []);

  const handleDeleteCategory = async (categoryId: number) => {
    PortalApis.deleteCategory(categoryId).then((category: Category) => {
      setCategories(categories.filter((category) => category.id != categoryId));
      setLinks(links.filter((link) => link.category.id != categoryId));
    });
  };

  const handleDeleteLink = async (categoryId: number, linkId: number) => {
    PortalApis.deleteLink(categoryId, linkId).then((link: Link) => {
      setLinks(links.filter((link: Link) => link.id != linkId));
    });
  };

  const handleDeleteNews = async (newsId: number) => {
    PortalApis.deleteNewsItem(newsId).then(() => {
      setNews(news.filter((news: News) => news.id != newsId));
    });
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  links.sort((a, b) => {
    const fa = a.category.name.toLowerCase(),
      fb = b.category.name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

  links
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .forEach((item) => {
      if (!groupedData[item.category.name]) {
        groupedData[item.category.name] = [];
      }
      groupedData[item.category.name].push(item);
    });

  return (
    <Box className={root}>
      <UpperBar bgColor={"secondary"}/>
      <Box className={categoriesBoxWrapper}>
        <Box className={boxHeader}>
          <Typography variant={"h5"}>Categories</Typography>
          <Tooltip title="Add New Category">
            <IconButton
              size="large"
              onClick={() => {
                setCategoryFromData({ open: true, category: null });
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={width5}>#</TableCell>
                <TableCell className={widthAuto}>Category</TableCell>
                <TableCell className={widthAuto}>Description</TableCell>
                <TableCell className={width15}>Icon</TableCell>
                <TableCell className={width15}>Edit/Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories &&
                categories.map((category, index) => {
                  const Icon = useIcons(category.icon || "Category");
                  return (
                    <TableRow
                      hover
                      key={"category" + category.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {category.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {category.description
                          ? trunclateLongText(category.description)
                          : " -- "}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Icon fontSize="large" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title={"Edit"}>
                          <IconButton
                            size="large"
                            onClick={() =>
                              setCategoryFromData({
                                open: true,
                                category: category,
                              })
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Delete"}>
                          <IconButton
                            size="large"
                            onClick={() => {
                              if (category.links.length > 0) {
                                setWarning(
                                  "Deleting this category will delete all its related links"
                                );
                              }
                              setConfirmProps({
                                open: true,
                                data: { category: category },
                              });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className={categoriesBoxWrapper}>
        <Box className={boxHeader}>
          <Typography variant={"h5"}>Links</Typography>
          <Tooltip title="Add New Link">
            <IconButton
              size="large"
              onClick={() => setLinkFromData({ open: true, link: null })}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={width20}>Title</TableCell>
                <TableCell className={width20}>URL</TableCell>
                <TableCell className={width10}>File</TableCell>
                <TableCell className={width25}>Content</TableCell>
                <TableCell className={width10}>Icon</TableCell>
                <TableCell className={width15}>Edit/Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(groupedData).map((category) => (
                <>
                  <TableRow sx={{ bgcolor: `${theme.palette.primary.main}` }}>
                    <TableCell colSpan={7}>{category}</TableCell>
                  </TableRow>
                  {groupedData[category].map((link, index) => {
                    const Icon = useIcons(link.icon);
                    return (
                      <TableRow
                        hover
                        key={"link" + link.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {link.title}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <a href={link.url} target={"_blank"}>
                            {link.url || "-"}
                          </a>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <a href={link.file} target={"_blank"}>
                            {link.file ? "Click Here" : "-"}
                          </a>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {link.content
                            ? trunclateLongText(link.content)
                            : " -- "}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Icon fontSize="large" />
                        </TableCell>
                        <TableCell>
                          <Tooltip title={"Edit"}>
                            <IconButton
                              size="large"
                              onClick={() =>
                                setLinkFromData({ open: true, link: link })
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"Delete"}>
                            <IconButton
                              size="large"
                              onClick={() =>
                                setConfirmProps({
                                  open: true,
                                  data: { link: link },
                                })
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={links.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
      <Box className={categoriesBoxWrapper}>
        <Box className={boxHeader}>
          <Typography variant={"h5"}>News</Typography>
          <Tooltip title="Add News">
            <IconButton
              size="large"
              onClick={() => setNewsFromData({ open: true, news: null })}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={width5}>#</TableCell>
                <TableCell className={width20}>Title</TableCell>
                <TableCell className={width25}>Content</TableCell>
                <TableCell className={width15}>Image</TableCell>
                <TableCell className={width20}>Deadline Date</TableCell>
                <TableCell className={width15}>Edit/Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {news &&
                news.map((item, index) => {
                  return (
                    <TableRow
                      hover
                      key={"category" + item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.title}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.summary
                          ? trunclateLongText(item.summary)
                          : " -- "}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <a href={item.image} target={"_blank"}>
                          {item.image ? "Click Here" : "-"}
                        </a>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.deadline_date}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title={"Edit"}>
                          <IconButton
                            size="large"
                            onClick={() =>
                              setNewsFromData({
                                open: true,
                                news: item,
                              })
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Delete"}>
                          <IconButton
                            size="large"
                            onClick={() => {
                              setConfirmProps({
                                open: true,
                                data: { news: item },
                              });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {categoryFromData.open && (
        <CategoryFormDialog
          open={categoryFromData.open}
          categories={categories}
          category={categoryFromData.category}
          setCategories={setCategories}
          setOpen={setCategoryFromData}
        />
      )}

      {linkFromData.open && (
        <LinkFormDialog
          open={linkFromData.open}
          link={linkFromData.link}
          links={links}
          categories={categories}
          setLinks={setLinks}
          setOpen={setLinkFromData}
        />
      )}

      {NewsFromData.open && (
        <NewsFormDialog
          open={NewsFromData.open}
          news={news}
          newsItem={NewsFromData.news}
          setNews={setNews}
          setOpen={setNewsFromData}
        />
      )}

      {confirmProps.open && (
        <ConfirmationDialog
          title={`Delete ${
            confirmProps.data && confirmProps.data.category
              ? "Category"
              : "Link"
          }`}
          open={confirmProps.open}
          setOpen={setConfirmProps}
          setWarning={setWarning}
          onConfirm={() => {
            if (confirmProps.data.category) {
              handleDeleteCategory(confirmProps.data.category.id);
            } else if (confirmProps.data.link) {
              handleDeleteLink(
                confirmProps.data.link.category.id,
                confirmProps.data.link.id
              );
            } else if (confirmProps.data.news) {
              handleDeleteNews(confirmProps.data.news.id);
            }
          }}
        >
          Are you sure you want to delete this{" "}
          {confirmProps.data.category ? "Category" : "Link"}?
          {warning && (
            <Alert severity="warning" sx={{ mt: "10px" }}>
              {warning}
            </Alert>
          )}
        </ConfirmationDialog>
      )}
    </Box>
  );
};
export default Settings;
