import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,FormControlLabel, Checkbox,Paper, Switch, TextField, Button } from "@mui/material";

import { RecognitionCategory} from "../../types/types";
import RecognitionsApis from "../../apis/RecognitionsApi";
import { useDispatch, useSelector } from "react-redux";
import { setRecognitionsCategoriesAction } from "../../redux/actions/recognitions/actions";
import { RootState } from "../../redux/store";



const RecognitionsCategories = ({recognitionsCategories}: any) => {
  const [newReward, setNewReward] = useState({ reward_type: "", price: "", is_visible: true });

  const dispatch = useDispatch();


  // Toggle is_visible state and update API
  const handleToggleVisibility = async (id: number, currentValue: boolean) => {
    try {
      // Toggle the visibility value
      const updatedCategory = await RecognitionsApis.updateRecognitionCategoryVisibility({id:id, is_visible:!currentValue});
  
      // Update Redux state
      const updatedCategories = recognitionsCategories.map((category: RecognitionCategory) =>
        category.id === id ? { ...category, is_visible: updatedCategory.is_visible } : category
      );
  
      dispatch(setRecognitionsCategoriesAction(updatedCategories));
    } catch (error) {
      console.error("Error updating visibility:", error);
    }
  };

  // Add new reward
  const handleAddReward = async () => {
    if (!newReward.reward_type || !newReward.price) return; // Simple validation

    
    RecognitionsApis.addNewRecognitionCategory(newReward)
      .then((recognitionCategory: RecognitionCategory) => {

        dispatch(setRecognitionsCategoriesAction([...recognitionsCategories, recognitionCategory]));
        setNewReward({reward_type: "", price: "", is_visible: true }); // Reset form
  
      }).catch((error)=>{
        console.error("Error creating category", error);
      });
  
    

  };

  return (
    <div>
      <h2>Rewards List</h2>
      {/* New Reward Form */}
      <div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
        <TextField label="Reward Type" value={newReward.reward_type} onChange={(e) => setNewReward({ ...newReward, reward_type: e.target.value })} />
        <TextField label="Price" type="number" value={newReward.price} onChange={(e) => setNewReward({ ...newReward, price: e.target.value })} />
        <FormControlLabel 
          control={
            <Checkbox 
              checked={newReward.is_visible} 
              onChange={(e) => setNewReward({ ...newReward, is_visible: e.target.checked })} 
            />
          } 
          label="Visible" 
        />
        <Button variant="contained" color="primary" onClick={handleAddReward}>Add Reward</Button>
      </div>

      {/* Rewards Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Reward Type</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Visible</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recognitionsCategories.map(recognitionCategory => (
              <TableRow key={recognitionCategory.id}>
                <TableCell>{recognitionCategory.reward_type}</TableCell>
                <TableCell>₪{recognitionCategory.price}</TableCell>
                <TableCell>
                  <Switch
                    checked={recognitionCategory.is_visible}
                    onChange={() => handleToggleVisibility(recognitionCategory.id, recognitionCategory.is_visible)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RecognitionsCategories;