import React, { useState } from "react";
import {
  Button,
  Tooltip,
  CircularProgress,
  Snackbar,
  Alert
} from "@mui/material";
import {
  FileDownload as DownloadIcon
} from "@mui/icons-material";
import IdeasApi from "../../apis/IdeasApi";


const ExportButton: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // Handle export action
  const handleExport = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Make API request
      const response = await  IdeasApi.exportIdeas();
        
      // Check if the response has data
      if (!response.data || response.data.size === 0) {
        throw new Error("Received empty file");
      }

      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      const filename = `ideas_export_${timestamp}.xlsx`;
          
      // Create a blob with the correct type
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
          
      // Use browser's built-in download functionality
      const url = window.URL.createObjectURL(blob);
          
      const link = document.createElement("a");
      link.href = url;
      link.download = filename; 
          
      // Append to body, click, and clean up
      document.body.appendChild(link);
      link.click();
          
      // Small delay before cleanup
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 100);
          
    } catch (err) {
      console.error("Error during export:", err);
      setError("Failed to export ideas. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  // Handle error alert close
  const handleErrorClose = () => {
    setError(null);
  };
  
  return (
    <>
      <Tooltip title="Export Ideas to Excel">
        <Button
          variant="outlined"
          color="primary"
          startIcon={loading ? <CircularProgress size={20} /> : <DownloadIcon />}
          onClick={handleExport}
          disabled={loading}
        >
          Export to Excel
        </Button>
      </Tooltip>
      
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error" variant="filled">
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ExportButton;