import {useEffect, useState} from "react";
import { News } from "../../types/types";
import CampaignIcon from "@mui/icons-material/Campaign";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import newsImg from "../../images/news-image.png";
import { useTickerStyles } from "./Ticker.styles";
import { trunclateLongText } from "../../utils";
import {useSearchParams} from "react-router-dom";
import "react-quill/dist/quill.snow.css";

export const Ticker = ({ newsItems }: { newsItems: News[] }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useTickerStyles();
  const [dialog, setDialog] = useState<{
    item: News | null;
    open: boolean;
  }>({ item: null, open: false });

  const CheckLang = (summary: string) => {
    const arabic = /[\u0600-\u06FF]/;
    return arabic.test(summary);
  };

  useEffect(() => {
    const news = searchParams.get("news");
    if(news && newsItems.length){
      const newsItem = newsItems.find((item) => item.id === parseInt(news));

      newsItem && setDialog({ open: true, item: newsItem });
    }
  }, [searchParams]);

  const handleClose = () => {
    setDialog({ open: false, item: null });
    const news = searchParams.get("news");
    if(news){
      searchParams.delete("news");
      setSearchParams(searchParams);
    }
  };

  const generateText = (newsItem: News, key: number) => {
    const isArabic = CheckLang(newsItem.summary);
    return (
      <div
        className={classes.news}
        key={key}
        onClick={() => setDialog({ open: true, item: newsItem })}
      >
        <img
          src={newsItem.image || newsImg}
          alt={newsItem.title}
          width={"35px"}
          height={"30px"}
          style={{ marginRight: "5px" }}
          loading="lazy"
        />
        <span
          style={{ fontWeight: "bold", color: "#af0202", fontSize: "20px" }}
          dir="ltr"
        >
          {newsItem.title.charAt(0).toUpperCase() + newsItem.title.slice(1)} -{" "}
        </span>
        { newsItem.summary && <span
          style={{ color: "#56545A", fontWeight: "bold", fontSize: "20px" }}
          dir={isArabic ? "rtl" : "ltr"}
        >
          {trunclateLongText(newsItem.summary, true)}
        </span>}
      </div>
    );
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.latest}>
          <CampaignIcon style={{ color: "white" }} /> Announcements
        </div>
        <div className={classes.ticker}>
          <div className={classes.newsWrapper}>
            {newsItems.map((newsItem, key) => generateText(newsItem, key))}
          </div>
        </div>
      </div>
      <Dialog
        open={dialog.open}
        onClose={() => handleClose()}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          {dialog.item?.title}
          <IconButton
            aria-label="close"
            onClick={() =>handleClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {dialog.item && (
            <div className={"ql-editor"}
              dangerouslySetInnerHTML={{ __html: dialog.item?.content }}
            ></div>
          )}
          {dialog.item?.image && (
            <img
              className={classes.image}
              src={dialog.item.image || newsImg}
              alt={dialog.item.title}
              loading="lazy"
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
