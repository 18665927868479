import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { NonAuthRoutes } from "../../constants/routes/routes";

export const ProtectedRoute = ({ children }: any) => {
  const { userDetails } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
  }));

  if (!userDetails?.is_superuser) {
    return <Navigate to={NonAuthRoutes.login} replace />;
  }

  return children;
};

export default ProtectedRoute;
