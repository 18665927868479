import {Routes, Route, useNavigate, useLocation} from "react-router-dom";
import {
  AuthRoutes,
  NonAuthRoutes,
  ProtectedAuthRoutes,
} from "../constants/routes/routes";
import Home from "../containers/Home/Home";
import Login from "../containers/Login/login";
import Settings from "../containers/Settings/Settings";
import HRDashboard from "../containers/Recognitions/HRDashboard";
import ManagerDashboard from "../containers/Recognitions/ManagerDashboard";
import IdeasManagementDashboard from "../containers/ideas/IdeasManagementDashboard";


import ProtectedRoute from "../components/AuthRoutes/ProtectedRoute";
import SecuredRoute from "../components/AuthRoutes/SecuredRoute";
import RecognitionRequestRoute from "../components/AuthRoutes/RecognitionRequestRoute";
import RecognitionApprovalRoute from "../components/AuthRoutes/RecognitionApprovalRoute";

import {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";


const RouterComponent = () => {
  const { userDetails } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
  }));

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = sessionStorage.getItem("redirect_url");
    if(userDetails.id && redirect){
      redirect && navigate(redirect);
      sessionStorage.removeItem("redirect_url");
    }
  }, [userDetails, location]);

  return (
    <Routes>
      <Route
        path={AuthRoutes.root}
        element={
          <SecuredRoute>
            <Home />
          </SecuredRoute>
        }
      >
        <Route
          path={AuthRoutes.category}
          element={
            <SecuredRoute>
              <Home />
            </SecuredRoute>
          }
        />
        <Route
          path={AuthRoutes.subject}
          element={
            <SecuredRoute>
              <Home />
            </SecuredRoute>
          }
        />
      </Route>
      <Route path={NonAuthRoutes.login} element={<Login />} />
      <Route
        path={ProtectedAuthRoutes.settings}
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path={ProtectedAuthRoutes.recognitionRequest}
        element={
          <RecognitionRequestRoute>
            <ManagerDashboard />
          </RecognitionRequestRoute>
        }
      />
      <Route
        path={ProtectedAuthRoutes.recognitions}
        element={
          <RecognitionApprovalRoute>
            <HRDashboard />
          </RecognitionApprovalRoute>
        }
      />
      <Route
        path={ProtectedAuthRoutes.ideas}
        element={
          <SecuredRoute>
            <IdeasManagementDashboard />
          </SecuredRoute>
        }
      />
      <Route path={"*"} element={<Login />} />
    </Routes>
  );
};

export default RouterComponent;
