enum ProtectedAuthRoutes {
  settings = "/settings",
  recognitionRequest = "/recognition-request",
  recognitions = "/recognitions",
  ideas = "/ideas"
}

enum AuthRoutes {
  root = "/",
  category = "category/:categoryId",
  subject = "category/:categoryId/subject/:subjectId"
}


enum NonAuthRoutes {
  login = "/login",
}

export {
  ProtectedAuthRoutes,
  AuthRoutes,
  NonAuthRoutes
};