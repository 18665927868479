import { TextField, Select, MenuItem, Button, InputLabel, FormControl, Container, Paper, Typography, Box, AutocompleteRenderInputParams } from "@mui/material";

import { useState } from "react";
import { Employee} from "../../types/types";
import RecognitionsApis from "../../apis/RecognitionsApi";

import {  Autocomplete } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";



const ManagerNewRecognitionRequest = ({employees, recognitionsCategories, onSubmitSuccess, isLoading}:any) => {


  const { userDetails } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
  }));

  const [formData, setFormData] = useState({
    employee_email: "",
    recognition_category: 2,
    recognition_text: "",
    recognition_reason: "",
    budget_type: ""
  });

  const handleSubmit = (e:any) => {
    e.preventDefault();
    
    RecognitionsApis.addNewRequest({
      employee: formData.employee_email,
      manager: userDetails.email,
      category: formData.recognition_category,
      text: formData.recognition_text,
      reason: formData.recognition_reason,
      status: "Pending",
      budget_type: formData.budget_type,
    
    }).then((result) => {
      setFormData({
        employee_email: "",
        recognition_category: 2,
        recognition_text: "",
        recognition_reason: "",
        budget_type: ""
      });


      onSubmitSuccess();
    });
  };

  return (
    <Box sx={{ width: "90%", maxWidth: "1000px", mx: "auto" }}>     
     
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Typography component="div" variant="h4" gutterBottom align="center">
          Create Recognition Request
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>

          {<Autocomplete
            disablePortal
            id="combo-box-demo"
            options={employees}
            getOptionLabel={(option: Employee) => option?.name || ""} // Use "name" instead of "label"
            value={(employees || []).find((emp: Employee) => emp.email === formData.employee_email) || null}
            onChange={(event, newValue) => setFormData({ ...formData, employee_email: newValue ? newValue.email : "" })}
            renderInput={(params) => 
              <TextField
                {...params}
                label="Employee Name"
                variant="outlined"
              />
            }
          />
          }
            
           
        
          {recognitionsCategories && recognitionsCategories.length > 0 && <FormControl fullWidth>
            <InputLabel id="recognition-category-label">Recognition Gift Category</InputLabel>
            <Select
              labelId="recognition-category-label"
              id="recognition-category-select"
              value={formData.recognition_category}
              label="Recognition Gift Category"
              onChange={(e) => setFormData({ ...formData, recognition_category: Number(e.target.value) })}
            >
              {recognitionsCategories.map((category) => (
                category.is_visible && <MenuItem key={category.id} value={category.id}>
                  {category.reward_type} - {category.price} (NIS)
                </MenuItem>
                
              ))}
            </Select>
          </FormControl>
          }

          <TextField
            label="Recognition Text (Optional)"
            multiline
            rows={4} // Adjust the number of rows as needed
            variant="outlined"
            fullWidth
            value={formData.recognition_text}
            onChange={(e) => setFormData({ ...formData, recognition_text: e.target.value })}
          />
          <TextField
            label="Recognition Reason (Mandatory)"
            variant="outlined"
            multiline
            rows={4} 
            fullWidth
            required
            value={formData.recognition_reason}
            onChange={(e) => setFormData({ ...formData, recognition_reason: e.target.value })}
          />
          <FormControl fullWidth>
            <InputLabel id="budget-type-label">Budget Type</InputLabel>
            <Select
              labelId="budget-type-label"
              id="budget-type-select"
              value={formData.budget_type}
              label="Budget Type"
              onChange={(e) => setFormData({ ...formData, budget_type: e.target.value })}
            >
              <MenuItem value="ASAL">ASAL Budget</MenuItem>
              <MenuItem value="Client">Client Budget</MenuItem>
            </Select>
          </FormControl>
          
          <Button variant="contained" disabled={isLoading} color="primary" type="submit" sx={{ mt: 2 }}>
            
            {isLoading ? "Submitting..." : "Submit Request"}
          </Button>
        </Box>
      </Paper>
      
    </Box>
  );
};

export default ManagerNewRecognitionRequest;
