import { Box } from "@mui/material";
import { ContentStyles } from "./SubjectContent.style";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Link } from "../../types/types";

interface ParentProps {
  selectedSubject: Link
}

const SubjectContent = ({ selectedSubject } : ParentProps) => {
  const subject = useSelector((state: RootState) => state.categories.selectedSubject); 
  const classes = ContentStyles();
  const content = subject?.content || selectedSubject.content;
  const file = subject?.file || selectedSubject.file;
  const url = subject?.url || selectedSubject.url;
  const link = file || url;

  return (
    <Box className={classes.content}>
      {link && (
        <a href={link} target="_blank">
          {url ? subject?.url || selectedSubject?.url :
            subject?.title || selectedSubject?.title}
        </a>
      )}
      {content}
    </Box>
  );
};

export default SubjectContent;
