import { categoriesNavigatorInitialPath } from "../../constants";
import { SET_CATEGORIES_PATHS, SET_CATEGORIES, SET_SELECTED_CATEGORY, SET_SELECTED_SUBJECT } from "../actions/categories/types";


const initialState = {
  paths: [categoriesNavigatorInitialPath]
};

const categoriesReducer = (state = initialState, action: any): any => {
  switch (action.type) {
  case SET_CATEGORIES_PATHS:
    return { ...state, paths: action.payload.path };
  case SET_CATEGORIES:
    return { ...state, categories: action.payload.categories };
  case SET_SELECTED_CATEGORY:
    return { ...state, selectedCategory: action.payload.category };
  case SET_SELECTED_SUBJECT:
    return { ...state, selectedSubject: action.payload.subject };
  default:
    return state;
  }
};

export { initialState, categoriesReducer };