import { makeStyles } from "@mui/styles";
import theme from "../../Theme";

export const useTickerStyles = makeStyles(() => ({
  "@keyframes newsTicker": {
    "0%": {
      transform: "translate3d(0, 0, 0)",
      visibility: "visible",
    },
    "100%": {
      transform: "translate3d(-100%, 0, 0)",
    },
  },
  "@keyframes neon": {
    "50%": {
      textShadow:
        "0 0 2px #800e0b, 0 0 5px #800e0b, 0 0 5px #800e0b, 0 0 5px #800e0b, 0 0 2px #800e0b, 4px 4px 2px #40340a",
    },
    "100%": {
      textShadow:
        "0 0 2px #800e0b, 0 0 5px #800e0b, 0 0 5px #800e0b, 0 0 5px #800e0b, 0 0 2px #800e0b, 4px 4px 2px #40340a",
    },
  },
  wrapper: {
    border: "2px solid",
    position: "fixed",
    height: "50px",
    bottom: "1px",
    width: "100%",
    backgroundColor: "white",
    borderColor: "#af0202",
  },
  latest: {
    background:
      "linear-gradient(90deg, rgba(175,2,2,1) 0%, rgba(245,135,0,1) 50%, rgba(255,220,93,1) 100%)",
    color: "white",
    fontWeight: "bold",
    width: "165px",
    bottom: "3px",
    height: "46px",
    position: "fixed",
    padding: "11px 8px 11px",
    "& > * ": {
      verticalAlign: "middle",
    },
    animation: "$neon 1s ease infinite",
  },
  ticker: {
    width: "100%",
    height: "47px",
    overflow: "hidden",
    paddingLeft: "100%",
    boxSizing: "content-box",
    bottom: "3px",
    position: "fixed",
    marginLeft: "165px",
    backgroundColor: "white",
    mask: "linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,1) 4%)",
  },
  newsWrapper: {
    cursor: "pointer",
    display: "inline-block",
    whiteSpace: "nowrap",
    paddingRight: "100%",
    boxSizing: "content-box",
    animation: "$newsTicker linear 25s infinite",

    "&:hover": {
      animationPlayState: "paused",
    },
  },
  news: {
    display: "inline-block",
    height: "25px",
    padding: "11px",
    marginRight: "12px",
    "& > * ": {
      verticalAlign: "middle",
    },
  },
  image: {
    maxWidth: "600px",
  },
}));
