import React, { useState, useEffect } from "react";
import { 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Paper,
  Typography,
  Box,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Pagination,
  CircularProgress,
  Alert,
  IconButton,
  Tooltip,
  Stack,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Drawer,
  Divider,
  Card,
  CardContent,
  CardHeader,
  SelectChangeEvent,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Sort as SortIcon,
  Search as SearchIcon,
  RemoveRedEye as ViewIcon,
  Close as CloseIcon,
  LightbulbOutlined as LightbulbIcon,
  Email as EmailIcon,
  CalendarMonth as CalendarIcon,
  AttachFile as AttachFileIcon,
  Error as ErrorIcon
} from "@mui/icons-material";
import IdeasApi from "../../apis/IdeasApi";
import ExportButton from "./ExportButton";
import AttachmentList from "./AttachmentList";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

import { 
  Idea, 
  IdeaStatus,
  ideaTypeLabels, 
  impactTypeLabels, 
  ideaStatusLabels,
  ideaStatusColors,
} from "../../types/Idea";

interface IdeasListProps {
  onViewIdea: (ideaId: number) => void;
  selectedIdeaId: number | null;
  showIdeaDetails: boolean;
  onCloseIdeaDetails: () => void;
}

const IdeasDashboard: React.FC<IdeasListProps> = ({ 
  onViewIdea,
  selectedIdeaId,
  showIdeaDetails,
  onCloseIdeaDetails
}) => {
  const [ideas, setIdeas] = useState<Idea[]>([]);
  const [selectedIdea, setSelectedIdea] = useState<Idea | null>(null);
  const [loading, setLoading] = useState(true);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [statusUpdateLoading, setStatusUpdateLoading] = useState<number | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    idea_type: "",
    potential_impact: "",
    status: "",
    search: "",
    ordering: "-creation_date"
  });
  const [showFilters, setShowFilters] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [ideaToUpdateStatus, setIdeaToUpdateStatus] = useState<{ id: number, currentStatus: IdeaStatus } | null>(null);
  const [newStatus, setNewStatus] = useState<IdeaStatus | "">("");
  
  // State for attachments
  const [attachments, setAttachments] = useState([]);
  const [attachmentsLoading, setAttachmentsLoading] = useState(false);
  const [attachmentsError, setAttachmentsError] = useState<string | null>(null);

  const { userDetails } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
  }));

  // Fetch ideas with current filters
  const fetchIdeas = async () => {
    try {
      setLoading(true);
      const { results, count } = await IdeasApi.getIdeas({ ...filters, page });
      setIdeas(results);
      setTotalCount(count);
      setError(null);
    } catch (err) {
      setError("Failed to load ideas. Please try again later.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch idea details when selectedIdeaId changes
  useEffect(() => {
    const fetchIdeaDetails = async () => {
      if (selectedIdeaId) {
        setDetailsLoading(true);
        try {
          const idea = await IdeasApi.getIdeaById(selectedIdeaId);
          setSelectedIdea(idea);
        } catch (err) {
          console.error("Error fetching idea details:", err);
          setError("Failed to load idea details.");
        } finally {
          setDetailsLoading(false);
        }
      } else {
        setSelectedIdea(null);
      }
    };

    fetchIdeaDetails();
  }, [selectedIdeaId]);

  // Fetch attachments when the selected idea changes
  useEffect(() => {
    const fetchAttachments = async () => {
      if (!selectedIdeaId) return;
      
      try {
        setAttachmentsLoading(true);
        const attachmentsData = await IdeasApi.getAttachments(selectedIdeaId);
        setAttachments(attachmentsData.results);
        setAttachmentsError(null);
      } catch (err) {
        console.error("Error fetching attachments:", err);
        setAttachmentsError("Failed to load attachments. Please try again.");
      } finally {
        setAttachmentsLoading(false);
      }
    };

    if (selectedIdeaId && showIdeaDetails) {
      fetchAttachments();
    }
  }, [selectedIdeaId, showIdeaDetails]);

  // Initial load and reload when filters or page changes
  useEffect(() => {
    fetchIdeas();
  }, [page, filters]);

  // Handle page change
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // Handle filter changes
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name as string]: value }));
    setPage(1); // Reset to first page when filters change
  };

  // Handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(prev => ({ ...prev, search: e.target.value }));
  };

  // Handle search submit
  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    fetchIdeas();
  };

  // Handle sorting
  const handleSortChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setFilters(prev => ({ ...prev, ordering: e.target.value as string }));
  };

  // Handle status dialog opening
  const handleOpenStatusDialog = (ideaId: number, currentStatus: IdeaStatus) => {
    // Only reviewers can change status
    if (!userDetails.is_ideas_reviewer) {
      setError("You don't have permission to update idea status.");
      return;
    }

    setIdeaToUpdateStatus({ id: ideaId, currentStatus });
    setNewStatus(currentStatus);
    setStatusDialogOpen(true);
  };

  // Handle status dialog closing
  const handleCloseStatusDialog = () => {
    setStatusDialogOpen(false);
    setIdeaToUpdateStatus(null);
    setNewStatus("");
  };

  // Handle new status selection
  const handleNewStatusChange = (event: SelectChangeEvent<string>) => {
    setNewStatus(event.target.value as IdeaStatus);
  };

  // Handle idea status update
  const handleUpdateStatus = async () => {
    if (!ideaToUpdateStatus || newStatus === "") return;

    try {
      setStatusUpdateLoading(ideaToUpdateStatus.id);
      await IdeasApi.updateIdeaStatus({ 
        id: ideaToUpdateStatus.id, 
        status: newStatus as IdeaStatus 
      });
      
      // Update the local state to reflect the change
      setIdeas(ideas.map(idea => 
        idea.id === ideaToUpdateStatus.id ? { ...idea, status: newStatus as IdeaStatus } : idea
      ));

      // If we're viewing this idea's details, update it there too
      if (selectedIdea && selectedIdea.id === ideaToUpdateStatus.id) {
        setSelectedIdea({ ...selectedIdea, status: newStatus as IdeaStatus });
      }

      handleCloseStatusDialog();
    } catch (err) {
      setError("Failed to update idea status. Please try again.");
      console.error(err);
    } finally {
      setStatusUpdateLoading(null);
    }
  };

  // Function to determine if the current user can update the status
  const canUpdateStatus = () => {
    return userDetails.is_ideas_reviewer;
  };

  // Handle attachment deletion
  const handleAttachmentDeleted = (attachmentId: number) => {
    setAttachments(attachments.filter(attachment => attachment.id !== attachmentId));
  };

  // Determine if current user is the owner of the idea
  const isOwner = () => {
    if (!selectedIdea || !userDetails) return false;
    return selectedIdea.created_by === userDetails.id;
  };

  // Render status chip with appropriate color
  const renderStatusChip = (status: IdeaStatus, onClick?: () => void) => {
    const colorKey = ideaStatusColors[status] as "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
    
    return (
      <Chip 
        label={ideaStatusLabels[status]} 
        color={colorKey}
        size="small"
        onClick={onClick}
        sx={{ cursor: onClick ? "pointer" : "default" }}
      />
    );
  };

  // Render the idea details drawer
  const renderIdeaDetails = () => {
    if (!selectedIdea) return null;
    
    // Format creation date
    const formattedDate = new Date(selectedIdea.creation_date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric"
    });

    return (
      <Drawer
        anchor="right"
        open={showIdeaDetails}
        onClose={onCloseIdeaDetails}
        sx={{
          "& .MuiDrawer-paper": { 
            width: { xs: "100%", sm: "50%", md: "40%" },
            maxWidth: "600px",
            p: 2 
          },
        }}
      >
        {detailsLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ height: "100%", overflow: "auto" }}>
            <Box sx={{ 
              display: "flex", 
              justifyContent: "space-between", 
              alignItems: "center",
              mb: 2
            }}>
              <Typography variant="h5" component="h2" fontWeight="medium">
                Idea Details
              </Typography>
             
              <IconButton onClick={onCloseIdeaDetails} edge="end">
                <CloseIcon />
              </IconButton>
            </Box>
            
            <Divider sx={{ mb: 3 }} />
            
            <Typography variant="h6" gutterBottom>
              {selectedIdea.title}
            </Typography>
            
            <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
              {renderStatusChip(
                selectedIdea.status, 
                canUpdateStatus() ? 
                  () => handleOpenStatusDialog(selectedIdea.id, selectedIdea.status) : 
                  undefined
              )}
              <Chip 
                label={ideaTypeLabels[selectedIdea.idea_type]} 
                variant="outlined" 
                size="small"
              />
              {selectedIdea.potential_impact && (
                <Chip 
                  label={impactTypeLabels[selectedIdea.potential_impact]} 
                  variant="outlined" 
                  size="small"
                />
              )}
            </Stack>
            
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6} display="flex" alignItems="center">
                    <EmailIcon fontSize="small" sx={{ mr: 1, color: "text.secondary" }} />
                    <Typography variant="body2">
                      {selectedIdea.owner}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display="flex" alignItems="center">
                    <CalendarIcon fontSize="small" sx={{ mr: 1, color: "text.secondary" }} />
                    <Typography variant="body2">
                      {formattedDate}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            
            <Card sx={{ mb: 3 }}>
              <CardHeader title="Description" />
              <Divider />
              <CardContent>
                <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                  {selectedIdea.description}
                </Typography>
              </CardContent>
            </Card>
            
            {(selectedIdea.target_audience || 
              selectedIdea.estimated_resources || 
              selectedIdea.peer_support || 
              selectedIdea.time_to_implement) && (
              <Card sx={{ mb: 3 }}>
                <CardHeader title="Implementation Details" />
                <Divider />
                <CardContent>
                  <Grid container spacing={2}>
                    {selectedIdea.target_audience && (
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                          Target Audience
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                          {selectedIdea.target_audience}
                        </Typography>
                      </Grid>
                    )}
                    
                    {selectedIdea.estimated_resources && (
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                          Estimated Resources
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                          {selectedIdea.estimated_resources}
                        </Typography>
                      </Grid>
                    )}
                    
                    {selectedIdea.peer_support && (
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                          Peer Support
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                          {selectedIdea.peer_support}
                        </Typography>
                      </Grid>
                    )}
                    
                    {selectedIdea.time_to_implement && (
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                          Time to Implement
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                          {selectedIdea.time_to_implement}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            )}
            
            {/* Attachments Card - Replace the existing attachment section */}
            <Card>
              <CardHeader 
                title="Attachments" 
                avatar={<AttachFileIcon />}
                subheader={
                  !attachmentsLoading && 
                  `${attachments.length} attachment${attachments.length !== 1 ? "s" : ""}`
                }
              />
              <Divider />
              <CardContent>
                {attachmentsLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                    <CircularProgress size={24} />
                  </Box>
                ) : attachmentsError ? (
                  <Alert 
                    severity="error"
                    icon={<ErrorIcon />}
                    sx={{ mb: 2 }}
                  >
                    {attachmentsError}
                  </Alert>
                ) : (
                  <AttachmentList attachments={attachments}/>
                )}
              </CardContent>
            </Card>
          </Box>
        )}
      </Drawer>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Header with page title */}
      <Box sx={{ 
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center", 
        mb: 3 
      }}>
        <Typography variant="h4" component="h1" fontWeight="medium">
          Ideas Dashboard
        </Typography>
      </Box>

      {/* Search and Filters */}
      <Paper sx={{ p: 3, mb: 3, borderRadius: 2 }}>
        <Box sx={{ 
          display: "flex", 
          flexDirection: { xs: "column", sm: "row" }, 
          justifyContent: "space-between", 
          alignItems: { xs: "stretch", sm: "center" }, 
          mb: 2,
          gap: 2
        }}>
          {/* Search form */}
          <Box 
            component="form" 
            onSubmit={handleSearchSubmit} 
            sx={{ 
              display: "flex", 
              width: { xs: "100%", sm: "60%" },
              gap: 1
            }}
          >
            <TextField
              name="search"
              variant="outlined"
              placeholder="Search ideas..."
              size="small"
              fullWidth
              value={filters.search}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />
              }}
            />
            <Button type="submit" variant="contained">
              Search
            </Button>
          </Box>

          {/* Filter and Sort controls */}
          <Stack direction="row" spacing={1}>
            <Button 
              variant={showFilters ? "contained" : "outlined"}
              color="secondary"
              startIcon={<FilterListIcon />} 
              onClick={() => setShowFilters(!showFilters)}
            >
              Filters
            </Button>
            <Button 
              variant="outlined"
              startIcon={<SortIcon />}
              onClick={() => {
                const newOrdering = filters.ordering.startsWith("-") 
                  ? filters.ordering.substring(1) 
                  : `-${filters.ordering}`;
                setFilters(prev => ({ ...prev, ordering: newOrdering }));
              }}
            >
              {filters.ordering.startsWith("-") ? "Sort Ascending" : "Sort Descending"}
            </Button>

            <Box sx={{ display: "flex", gap: 2 }}>
              <ExportButton />
            </Box>
          </Stack>
        </Box>

        {/* Expanded filters section */}
        {showFilters && (
          <Box sx={{ 
            display: "flex", 
            flexDirection: { xs: "column", md: "row" }, 
            gap: 2,
            mt: 2
          }}>
            <FormControl sx={{ minWidth: { xs: "100%", md: 200 } }} size="small">
              <InputLabel id="idea-type-label">Idea Type</InputLabel>
              <Select
                labelId="idea-type-label"
                name="idea_type"
                value={filters.idea_type}
                label="Idea Type"
                onChange={handleFilterChange}
              >
                <MenuItem value="">All Types</MenuItem>
                {Object.entries(ideaTypeLabels).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: { xs: "100%", md: 200 } }} size="small">
              <InputLabel id="impact-label">Potential Impact</InputLabel>
              <Select
                labelId="impact-label"
                name="potential_impact"
                value={filters.potential_impact}
                label="Potential Impact"
                onChange={handleFilterChange}
              >
                <MenuItem value="">All Impacts</MenuItem>
                {Object.entries(impactTypeLabels).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: { xs: "100%", md: 200 } }} size="small">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                name="status"
                value={filters.status}
                label="Status"
                onChange={handleFilterChange}
              >
                <MenuItem value="">All Statuses</MenuItem>
                {Object.entries(ideaStatusLabels).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: { xs: "100%", md: 200 } }} size="small">
              <InputLabel id="sort-label">Sort By</InputLabel>
              <Select
                labelId="sort-label"
                name="ordering"
                value={filters.ordering}
                label="Sort By"
                onChange={handleSortChange}
              >
                <MenuItem value="-creation_date">Newest First</MenuItem>
                <MenuItem value="creation_date">Oldest First</MenuItem>
                <MenuItem value="title">Title (A-Z)</MenuItem>
                <MenuItem value="-title">Title (Z-A)</MenuItem>
                <MenuItem value="status">Status</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      </Paper>

      {/* Error Message */}
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {/* Ideas Table */}
      <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 2 }}>
        {loading ? (
          <Box sx={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            p: 4, 
            minHeight: 300 
          }}>
            <CircularProgress />
          </Box>
        ) : ideas.length === 0 ? (
          <Box sx={{ 
            p: 4, 
            textAlign: "center",
            minHeight: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2
          }}>
            <Typography variant="h6">
              No ideas found
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {filters.search || filters.idea_type || filters.potential_impact || filters.status
                ? "Try adjusting your filters to see more results" 
                : "Switch to the 'Submit New Idea' tab to add an idea"}
            </Typography>
            {(filters.search || filters.idea_type || filters.potential_impact || filters.status) && (
              <Button 
                variant="outlined" 
                onClick={() => setFilters({
                  idea_type: "",
                  potential_impact: "",
                  status: "",
                  search: "",
                  ordering: "-creation_date"
                })}
              >
                Clear Filters
              </Button>
            )}
          </Box>
        ) : (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="ideas table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ideas.map((idea) => (
                    <TableRow 
                      key={idea.id} 
                      hover
                      sx={{ 
                        "&:last-child td, &:last-child th": { border: 0 },
                        backgroundColor: selectedIdeaId === idea.id ? "action.selected" : "inherit"
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography 
                          variant="body1" 
                          fontWeight="medium"
                        >
                          {idea.title}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip 
                          label={ideaTypeLabels[idea.idea_type]} 
                          size="small"
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>
                        {canUpdateStatus() ? (
                          <Box 
                            onClick={() => handleOpenStatusDialog(idea.id, idea.status)}
                            sx={{ cursor: "pointer" }}
                          >
                            {renderStatusChip(idea.status)}
                          </Box>
                        ) : (
                          renderStatusChip(idea.status)
                        )}
                      </TableCell>
                      <TableCell>{idea.owner}</TableCell>
                      <TableCell>{new Date(idea.creation_date).toLocaleDateString()}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="View Details">
                          <IconButton 
                            color="info" 
                            onClick={() => onViewIdea(idea.id)}
                            size="small"
                          >
                            <ViewIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            
            {/* Pagination */}
            <Box sx={{ 
              display: "flex", 
              justifyContent: "space-between", 
              alignItems: "center",
              p: 2
            }}>
              <Typography variant="body2" color="text.secondary">
                Showing {ideas.length} of {totalCount} ideas
              </Typography>
              <Pagination 
                count={Math.ceil(totalCount / 10)} 
                page={page} 
                onChange={handlePageChange} 
                color="primary" 
                shape="rounded"
              />
            </Box>
          </>
        )}
      </Paper>

      {/* Status Update Dialog */}
      {canUpdateStatus() && (
        <Dialog open={statusDialogOpen} onClose={handleCloseStatusDialog}>
          <DialogTitle>Update Idea Status</DialogTitle>
          <DialogContent>
            <Box sx={{ pt: 1 }}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="new-status-label">New Status</InputLabel>
                <Select
                  labelId="new-status-label"
                  value={newStatus}
                  label="New Status"
                  onChange={handleNewStatusChange}
                >
                  {Object.entries(ideaStatusLabels).map(([value, label]) => (
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseStatusDialog}>Cancel</Button>
            <Button 
              onClick={handleUpdateStatus} 
              variant="contained" 
              color="primary"
              disabled={!newStatus || statusUpdateLoading !== null}
            >
              {statusUpdateLoading !== null ? <CircularProgress size={24} /> : "Update"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      
      {/* Idea Details Drawer */}
      {renderIdeaDetails()}
    </Box>
  );
};

export default IdeasDashboard;