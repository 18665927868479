import {
  Alert,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Autocomplete
} from "@mui/material";
import { useEffect, useState } from "react";
import { Employee, RecognitionRequest } from "../../types/types";
import RecognitionsApis from "../../apis/RecognitionsApi";
import {  useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import RecognitionTable from "./RecognitionsTable";
import {Backdrop, CircularProgress} from "@mui/material";


const Loader = () => (
  <Backdrop open>
    <CircularProgress color="inherit" />
  </Backdrop>
);


export const Recognitions = ({employees, managers, recognitionRequests}: any) => {

  const [filteredData, setFilteredData] = useState(recognitionRequests); // Start with all data
  
  const [filters, setFilters] = useState<{
    employee_email: string,
    status: string,
    manager: string,
  }>({ employee_email: "", status: "", manager: "" });
  
  // Extract unique statuses & managers
  const recognitionStatuses = ["Pending",  "Approved", "Rejected"];  

  const [error] = useState("");


  // Handle filter changes
  const handleFilterChange = (key:string, value:string) => {
    setFilters({...filters, [key]:value});

  };


  useEffect(() => {
    const isFilterActive = filters.employee_email || filters.status || filters.manager; // Check if any filter is applied

    if (!isFilterActive) {
      setFilteredData(recognitionRequests); // Reset to original data
      return;
    }

    const newFilteredData = recognitionRequests.filter(item =>
      (!filters.employee_email || item.employee === filters.employee_email) &&
    (!filters.status || item.status === filters.status)&&
    (!filters.manager || item.manager === filters.manager)
    );

    setFilteredData(newFilteredData);

  }, [filters, recognitionRequests]); // Re-run when filters or data change


  // Function to handle status update
  const handleStatusChange = async (event:any, row: Employee) => {
    const newStatus = event.target.value;

    try {
    
      RecognitionsApis.updateRecognitionStatus({
        id:row.id,
        status: newStatus,
      
      
      }).then((recognitionRequest: RecognitionRequest) => {
     
  
        console.log("<<< request", recognitionRequest);
      });

      // Update the state
      setFilteredData((filteredData) =>
        filteredData.map((item) =>
          item.id === row.id ? { ...item, status: newStatus } : item
        )
      );

      // // Update the state
      // setRecognitionRequests((data) =>
      //   data.map((item) =>
      //     item.id === row.id ? { ...item, status: newStatus } : item
      //   )
      // );
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  return (
    <Box >
      {error && <Alert severity="error">{error}</Alert>}

      {/* Filters */}
      <Box display="flex" gap={2} mb={2}>

        <FormControl fullWidth variant="outlined">
          {employees && employees.length > 0 && <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={employees}
            getOptionLabel={(option: any) => option.name} // Use "name" instead of "label"
            value={employees.find(emp => emp.email === filters.employee_email) || null} // Match value to state
            onChange={(event, newValue) => handleFilterChange("employee_email", newValue ? newValue.email : "") }
            renderInput={(params) => 
              <TextField
                {...params}
                label="Employee Name"
                variant="outlined"
              />
            }
          />
          }
        </FormControl>

        {/* Employee Name Filter */}
        {/* Status Filter */}
        <FormControl fullWidth variant="outlined">
          <InputLabel>Status</InputLabel>
          <Select
            value={filters.status}
            onChange={(e) => handleFilterChange("status", e.target.value)}
            label="Status"
          >
            <MenuItem value="">All</MenuItem>
            {recognitionStatuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Manager Filter */}
        <FormControl fullWidth variant="outlined">
          {managers && managers.length > 0 && <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={managers}
            getOptionLabel={(option: any) => option.name} // Use "name" instead of "label"
            value={managers.find(emp => emp.email === filters.manager) || null} // Match value to state
            onChange={(event, newValue) => handleFilterChange("manager", newValue ? newValue.email : "") }
            renderInput={(params) => 
              <TextField
                {...params}
                label="Manager"
                variant="outlined"
              />
            }
          />
          }
        </FormControl>
      </Box>

      <RecognitionTable employees={employees} filteredData={filteredData} filterKey={JSON.stringify(filters)} editable={true} handleStatusChange={handleStatusChange}/>

    </Box>
  );
};
export default Recognitions;
