import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Alert,
  Stack,
  Paper,
  IconButton,
  Tooltip,
  Chip,
  Snackbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction
} from "@mui/material";
import { 
  Save as SaveIcon,
  Clear as ClearIcon,
  AttachFile as AttachFileIcon,
  InfoOutlined as InfoIcon,
  CheckCircleOutline as SuccessIcon,
  Delete as DeleteIcon,
  AddCircleOutline as AddIcon,
  InsertDriveFile as FileIcon,
  PictureAsPdf as PdfIcon,
  Image as ImageIcon
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import IdeasApi from "../../apis/IdeasApi";
import { 
  CreateIdeaDto, 
  ideaTypeLabels, 
  impactTypeLabels, 
  IdeaType, 
  ImpactType,
  FileWithPreview 
} from "../../types/Idea";
import { toast } from "react-toastify";

interface IdeaFormProps {
  onSubmitSuccess?: () => void;
}

const IdeaForm: React.FC<IdeaFormProps> = ({onSubmitSuccess}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<FileWithPreview[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [dragActive, setDragActive] = useState(false);

  // Set up form validation schema
  const validationSchema = Yup.object({
    title: Yup.string()
      .required("Title is required")
      .max(255, "Title must be at most 255 characters"),
    description: Yup.string()
      .required("Description is required"),
    idea_type: Yup.string()
      .required("Idea type is required")
      .oneOf(
        Object.keys(ideaTypeLabels) as IdeaType[], 
        "Invalid idea type"
      ),
    potential_impact: Yup.string()
      .nullable()
      .oneOf(
        [...Object.keys(impactTypeLabels) as ImpactType[], ""],
        "Invalid impact type"
      ),
  });

  // Set up form with Formik
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      idea_type: "" as IdeaType,
      potential_impact: "" as ImpactType | "",
      target_audience: "",
      estimated_resources: "",
      peer_support: "",
      time_to_implement: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setError(null);

      try {
        const formValues: CreateIdeaDto = {
          ...values,
          potential_impact: values.potential_impact || null,
        };
        
        // First create the idea
        const createdIdea = await IdeasApi.createIdea(formValues);
        
        // Then upload any attachments if there are any
        if (selectedFiles.length > 0) {
          await uploadAttachments(createdIdea.id);
        }
        

        // Show success message
        toast.success("Idea submitted successfully!", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
        
        // Reset form
        formik.resetForm();
        setSelectedFiles([]);

        // Call the onSubmitSuccess callback to navigate to the other tab
        if (onSubmitSuccess) {
          console.log("<<<< called ", onSubmitSuccess);
          onSubmitSuccess();
        }
        
      } catch (err) {
        console.error("Form submission error:", err);
        setError("Failed to submit idea. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });
  
  // Function to upload attachments
  const uploadAttachments = async (ideaId: number) => {
    try {
      // Create a FormData object to hold the files
      const formData = new FormData();
      
      // Append each file to the form data
      selectedFiles.forEach((fileItem) => {
        formData.append("files", fileItem.file);
      });
      
      // Upload the files
      await IdeasApi.uploadAttachments(ideaId, formData);
    } catch (err) {
      console.error("Error uploading attachments:", err);
      throw err;
    }
  };

  // Function to format file size
  const formatFileSize = (size: number): string => {
    if (size < 1024) {
      return `${size} B`;
    } else if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(1)} KB`;
    } else if (size < 1024 * 1024 * 1024) {
      return `${(size / (1024 * 1024)).toFixed(1)} MB`;
    } else {
      return `${(size / (1024 * 1024 * 1024)).toFixed(1)} GB`;
    }
  };
  
  // Function to check if file is an image
  const isImageFile = (file: File): boolean => {
    return file.type.startsWith("image/");
  };
  
  // Function to get appropriate icon for file type
  const getFileIcon = (fileType: string) => {
    if (fileType.includes("pdf")) {
      return <PdfIcon color="error" />;
    } else if (fileType.includes("image")) {
      return <ImageIcon color="primary" />;
    } else {
      return <FileIcon />;
    }
  };
  
  // Function to generate a unique ID
  const generateId = (): string => {
    return Math.random().toString(36).substr(2, 9);
  };

  // Handle file change
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const filesArray = Array.from(event.target.files);
      
      const newFiles: FileWithPreview[] = filesArray.map(file => {
        const id = generateId();
        let preview = undefined;
        
        if (isImageFile(file)) {
          preview = URL.createObjectURL(file);
        }
        
        return {
          file,
          id,
          preview,
          name: file.name,
          size: formatFileSize(file.size),
          type: file.type
        };
      });
      
      setSelectedFiles((prev) => [...prev, ...newFiles]);
    }
    
    // Reset input value
    if (event.target) {
      event.target.value = "";
    }
  };

  // Handle drag events
  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  // Handle drop event
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const filesArray = Array.from(e.dataTransfer.files);
      
      const newFiles: FileWithPreview[] = filesArray.map(file => {
        const id = generateId();
        let preview = undefined;
        
        if (isImageFile(file)) {
          preview = URL.createObjectURL(file);
        }
        
        return {
          file,
          id,
          preview,
          name: file.name,
          size: formatFileSize(file.size),
          type: file.type
        };
      });
      
      setSelectedFiles((prev) => [...prev, ...newFiles]);
    }
  };
  
  // Remove a file from selected files
  const handleRemoveFile = (fileId: string) => {
    // First, revoke any object URLs to prevent memory leaks
    const fileToRemove = selectedFiles.find(f => f.id === fileId);
    if (fileToRemove && fileToRemove.preview) {
      URL.revokeObjectURL(fileToRemove.preview);
    }
    
    // Then remove the file from the state
    setSelectedFiles(selectedFiles.filter(file => file.id !== fileId));
  };

  // Clear all files
  const handleClearAllFiles = () => {
    // Revoke all object URLs
    selectedFiles.forEach(file => {
      if (file.preview) {
        URL.revokeObjectURL(file.preview);
      }
    });
    
    // Clear the selected files
    setSelectedFiles([]);
  };

  // Handle form reset
  const handleReset = () => {
    formik.resetForm();
    handleClearAllFiles();
    setError(null);
  };

  return (
    <Box>
      {/* Form header */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" fontWeight="medium">
          Submit New Idea
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Share your innovative idea with the team
        </Typography>
      </Box>

      {/* Error display */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setError(null)}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      )}

      {/* Form */}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {/* Main form content */}
          <Grid item xs={12} md={8} lg={9}>
            {/* Basic information card */}
            <Card sx={{ mb: 3, borderRadius: 2 }}>
              <CardHeader 
                title="Basic Information" 
                titleTypographyProps={{ variant: "h6" }}
              />
              <Divider />
              <CardContent>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    id="title"
                    name="title"
                    label="Idea Title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    placeholder="A concise, descriptive title"
                    required
                    variant="outlined"
                  />
                  
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    label="Idea Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={
                      (formik.touched.description && formik.errors.description) || 
                      "Provide a detailed explanation including purpose and expected outcomes"
                    }
                    placeholder="Describe your idea in detail..."
                    multiline
                    rows={4}
                    required
                    variant="outlined"
                  />
                  
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} >
                      <FormControl 
                        fullWidth
                        error={formik.touched.idea_type && Boolean(formik.errors.idea_type)}
                        required
                        variant="outlined"
                        size="small"
                      >
                        <InputLabel id="idea-type-label">Idea Type</InputLabel>
                        <Select
                          labelId="idea-type-label"
                          id="idea_type"
                          name="idea_type"
                          value={formik.values.idea_type}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          label="Idea Type"
                        >
                          {Object.entries(ideaTypeLabels).map(([value, label]) => (
                            <MenuItem key={value} value={value}>{label}</MenuItem>
                          ))}
                        </Select>
                        {formik.touched.idea_type && formik.errors.idea_type && (
                          <FormHelperText>{formik.errors.idea_type}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                      <FormControl 
                        fullWidth
                        error={formik.touched.potential_impact && Boolean(formik.errors.potential_impact)}
                        variant="outlined"
                        size="small"
                      >
                        <InputLabel id="impact-label">Potential Impact</InputLabel>
                        <Select
                          labelId="impact-label"
                          id="potential_impact"
                          name="potential_impact"
                          value={formik.values.potential_impact}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          label="Potential Impact"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {Object.entries(impactTypeLabels).map(([value, label]) => (
                            <MenuItem key={value} value={value}>{label}</MenuItem>
                          ))}
                        </Select>
                        {formik.touched.potential_impact && formik.errors.potential_impact && (
                          <FormHelperText>{formik.errors.potential_impact}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Stack>
              </CardContent>
            </Card>

            {/* Implementation details card */}
            <Card sx={{ mb: 3, borderRadius: 2 }}>
              <CardHeader 
                title="Implementation Details" 
                titleTypographyProps={{ variant: "h6" }}
                action={
                  <Tooltip title="These fields are optional but provide valuable context">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                }
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="target_audience"
                      name="target_audience"
                      label="Target Audience"
                      value={formik.values.target_audience}
                      onChange={formik.handleChange}
                      placeholder="Who will benefit from the idea"
                      multiline
                      rows={3}
                      variant="outlined"
                      helperText="Identify the groups or individuals who will benefit"
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="estimated_resources"
                      name="estimated_resources"
                      label="Estimated Resources"
                      value={formik.values.estimated_resources}
                      onChange={formik.handleChange}
                      placeholder="Resources required to build the idea"
                      multiline
                      rows={3}
                      variant="outlined"
                      helperText="Personnel, budget, tools, or other resources needed"
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="peer_support"
                      name="peer_support"
                      label="Peer Support"
                      value={formik.values.peer_support}
                      onChange={formik.handleChange}
                      placeholder="Identify individuals or teams willing to assist"
                      multiline
                      rows={3}
                      variant="outlined"
                      helperText="List colleagues or teams who can help implement this idea"
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="time_to_implement"
                      name="time_to_implement"
                      label="Time to Implement"
                      value={formik.values.time_to_implement}
                      onChange={formik.handleChange}
                      placeholder="How long will it take to execute the idea"
                      variant="outlined"
                      helperText="Estimated timeline for implementation"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Attachments card */}
            <Card sx={{ borderRadius: 2 }}>
              <CardHeader 
                title="Attachments" 
                titleTypographyProps={{ variant: "h6" }}
                action={
                  <Tooltip title="Upload one or more files">
                    <IconButton color="primary" component="label">
                      <input
                        hidden
                        accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                      />
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                }
              />
              <Divider />
              <CardContent>
                {/* Drag & Drop Area */}
                <Box
                  sx={{ 
                    border: dragActive ? "2px dashed #1976d2" : "1px dashed rgba(0, 0, 0, 0.23)", 
                    borderRadius: 1,
                    p: 3,
                    mb: selectedFiles.length > 0 ? 2 : 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    backgroundColor: dragActive ? "rgba(25, 118, 210, 0.04)" : "transparent",
                    transition: "all 0.2s ease"
                  }}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <input
                    accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                    id="attachments-input"
                    type="file"
                    style={{ display: "none" }}
                    multiple
                    onChange={handleFileChange}
                  />
                  
                  <AttachFileIcon color="action" fontSize="large" />
                  <Typography variant="body1" align="center" color="text.secondary">
                    Drag & drop files here, or 
                    <label htmlFor="attachments-input">
                      <Button
                        component="span"
                        color="primary"
                        sx={{ mx: 1 }}
                      >
                        Browse
                      </Button>
                    </label>
                    to upload
                  </Typography>
                  <Typography variant="caption" align="center" color="text.secondary">
                    Supported formats: Images, PDF, Word, Excel, PowerPoint
                  </Typography>
                </Box>
                
                {/* Selected Files List */}
                {selectedFiles.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
                      <Typography variant="subtitle2">
                        Selected Files ({selectedFiles.length})
                      </Typography>
                      
                      <Button 
                        size="small" 
                        color="error" 
                        variant="outlined" 
                        startIcon={<ClearIcon />}
                        onClick={handleClearAllFiles}
                      >
                        Clear All
                      </Button>
                    </Box>
                    
                    <Paper variant="outlined" sx={{ maxHeight: 200, overflow: "auto" }}>
                      <List dense>
                        {selectedFiles.map((file) => (
                          <ListItem key={file.id}>
                            <ListItemIcon>
                              {getFileIcon(file.type)}
                            </ListItemIcon>
                            
                            <ListItemText 
                              primary={file.name}
                              secondary={file.size}
                              primaryTypographyProps={{
                                noWrap: true, 
                                style: { maxWidth: "250px" }
                              }}
                            />
                            
                            {file.preview && (
                              <Box 
                                component="img" 
                                src={file.preview} 
                                sx={{ 
                                  width: 40, 
                                  height: 40, 
                                  objectFit: "cover", 
                                  borderRadius: 1,
                                  mr: 1
                                }} 
                              />
                            )}
                            
                            <ListItemSecondaryAction>
                              <IconButton 
                                edge="end" 
                                aria-label="delete" 
                                onClick={() => handleRemoveFile(file.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Submission sidebar */}
          <Grid item xs={12} md={4} lg={3}>
            <Box sx={{ position: { md: "sticky" }, top: { md: "20px" } }}>
              <Card sx={{ borderRadius: 2 }}>
                <CardHeader 
                  title="Submit" 
                  titleTypographyProps={{ variant: "h6" }}
                />
                <Divider />
                <CardContent>
                  <Stack spacing={3}>
                    <Box>
                      <Typography variant="body2" paragraph>
                        Review your idea before submitting
                      </Typography>
                      
                      <Paper 
                        variant="outlined" 
                        sx={{ p: 2, bgcolor: "background.default", mb: 2 }}
                      >
                        <Stack spacing={1}>
                          <Typography variant="subtitle2" noWrap>
                            {formik.values.title || "(No title)"}
                          </Typography>
                          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                            {formik.values.idea_type && (
                              <Chip 
                                label={ideaTypeLabels[formik.values.idea_type]} 
                                size="small" 
                                color="primary"
                                variant="outlined"
                              />
                            )}
                            {formik.values.potential_impact && (
                              <Chip 
                                label={impactTypeLabels[formik.values.potential_impact as ImpactType]} 
                                size="small"
                                color="secondary"
                                variant="outlined"
                              />
                            )}
                          </Box>
                          {selectedFiles.length > 0 && (
                            <Chip 
                              icon={<AttachFileIcon />}
                              label={`${selectedFiles.length} attachment${selectedFiles.length > 1 ? "s" : ""}`}
                              size="small"
                              color="info"
                              variant="outlined"
                            />
                          )}
                        </Stack>
                      </Paper>
                    </Box>

                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        startIcon={loading ? undefined : <SaveIcon />}
                        disabled={loading || !formik.isValid || !formik.dirty}
                      >
                        {loading ? <CircularProgress size={24} /> : "Submit Idea"}
                      </Button>
                      
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={handleReset}
                        sx={{ mt: 2 }}
                        startIcon={<ClearIcon />}
                        disabled={!formik.dirty && selectedFiles.length === 0}
                      >
                        Reset Form
                      </Button>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </form>

      {/* Success snackbar */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage(null)}
        message={
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <SuccessIcon color="success" />
            {successMessage}
          </Box>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </Box>
  );
};

export default IdeaForm;