import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Select,
} from "@mui/material";
import { Employee, RecognitionRequest } from "../../types/types";

interface RecognitionsTableProps {
  employees: Employee[],
  filteredData: RecognitionRequest[];
  key: string;
  editable:boolean;
  handleStatusChange: (status: string) => void;
}

export const RecognitionsTable = ({employees, filteredData, filterKey, editable, handleStatusChange}: RecognitionsTableProps) => {
 
  // Extract unique statuses & managers
  const recognitionStatuses = ["Pending",  "Approved", "Rejected"];
  return (

    <TableContainer component={Paper} elevation={3} >
      <Table key={filterKey}> {/* Forces re-render on filter change */}
        <TableHead>
          <TableRow>
            <TableCell><b>Employee Name</b></TableCell>
            {editable && <TableCell><b>Requester Name</b></TableCell>}
            <TableCell><b>Recognition Category</b></TableCell>
            <TableCell><b>Recognition Text</b></TableCell>
            <TableCell><b>Recognition Reason</b></TableCell>
            <TableCell><b>Budget Type</b></TableCell>
            <TableCell><b>Status</b></TableCell>
            <TableCell><b>Requested At</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
    

          {filteredData.length > 0 ? (
            filteredData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{employees.find((emp:Employee) => emp.email === row.employee)?.name || null} </TableCell>
                {editable && <TableCell>{employees.find((mgr:Employee) => mgr.email === row.manager)?.name || null} </TableCell> }
                <TableCell>{row.category_name || "-"}</TableCell>
                <TableCell>{row.text}</TableCell>
                <TableCell>{row.reason}</TableCell>
                <TableCell>{row.budget_type}</TableCell>
                {/* Status Column */}
                <TableCell>
                  {editable
                   &&  <Select
                     value={row.status}
                     onChange={(e) => handleStatusChange(e, row)}
                     size="small"
                        
                   >
                     {recognitionStatuses.map((status) => (
                       <MenuItem
                         key={status}
                         value={status}
                       >
                         {status}
                       </MenuItem>
                     ))}
                   </Select> }
                  {!editable && row.status }
   
                </TableCell>
                <TableCell>{row.created_at ? new Date(row.created_at).toISOString().split("T")[0] : "-"}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} align="center">
                  No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
     
  );
};
export default RecognitionsTable;
