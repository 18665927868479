import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Autocomplete, TextField, InputAdornment } from "@mui/material";
import { useSearchStyle } from "./SearchBox.style";
import { Category, Link } from "../../types/types";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCategory,
  setSelectedSubject,
} from "../../redux/actions/categories/actions";
import { RootState } from "../../redux/store";

interface searchType {
  link: Link;
  category: Category;
}

const SearchBox = () => {
  const classes = useSearchStyle();
  const { searchPaper, inputText } = classes;
  const [data, setData] = useState<searchType[]>([]);
  const [link, setLink] = useState("");
  const [open, setOpen] = useState(false);
  const categories = useSelector(
    (state: RootState) => state.categories.categories
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getCategories = async () => {
    const data: searchType[] = [];
    categories?.map((category: Category) => {
      category.links.map((link: Link) => {
        data.push({ link: link, category: category });
      });
    });
    setData(data);
  };

  useEffect(() => {
    getCategories();
  }, [categories]);

  const handleChange = (link?: Link) => {
    if(link) {
      if(link.file) {
        
        handleFileChange(link.file);
      }
      if(link.url) {
        handleURLChange(link.url);
      }
    }    
  };

  const handleFileChange = (file: string) => {
    window?.open(file, "_blank")?.focus();
  };
  
  const handleURLChange = (url: string) => {
    window?.open(url, "_blank")?.focus();
  };

  return (
    <Paper
      component="form"
      className={searchPaper}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Autocomplete
        id="search"
        options={data}
        groupBy={(option) => option.category?.name}
        getOptionLabel={(option) => option.link?.title}
        sx={{ flex: 1 }}
        open={open}
        forcePopupIcon={false}
        value={null}
        blurOnSelect={true}
        onInputChange={(e, value) => {
          if (value.length === 0) {
            if (open) setOpen(false);
          } else {
            if (!open) setOpen(true);
          }
        }}
        onClose={() => setOpen(false)}
        onChange={(event, value) =>  handleChange(value?.link)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={"What are you looking for?"}
            className={inputText}

            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Paper>
  );
};
export default SearchBox;
