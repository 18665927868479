import { Category, Path } from "../../../types/types";
import { SET_CATEGORIES, SET_CATEGORIES_PATHS, SET_SELECTED_CATEGORY, SET_SELECTED_SUBJECT } from "./types";

export const setCategoriesPaths = (path: Path) => (dispatch: any) => {
  dispatch({
    type: SET_CATEGORIES_PATHS,
    payload: { path }
  });
};

export const setCategories = (categories: Category[]) => (dispatch: any) => {
  dispatch({
    type: SET_CATEGORIES,
    payload: { categories }
  });
};

export const setSelectedCategory = (category: Category) => (dispatch: any) => {
  dispatch({
    type: SET_SELECTED_CATEGORY,
    payload: { category }
  });
};

export const setSelectedSubject = (subject: Category) => (dispatch: any) => {
  dispatch({
    type: SET_SELECTED_SUBJECT,
    payload: { subject }
  });
};