import {
  Alert,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Autocomplete
} from "@mui/material";
import { useEffect, useState } from "react";
import { Employee, RecognitionRequest} from "../../types/types";
import RecognitionsApis from "../../apis/RecognitionsApi";
import {  useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import RecognitionTable from "./RecognitionsTable";


export const ManagerRecognitions = ({employees}: any) => {

  const errors=[];
  // Filters state

  const { userDetails } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
  }));

  const [managerEmployees, setManagerEmployees] = useState(employees.filter((emp: Employee) => emp.manager_email == userDetails.email));

  const [recognitionRequests, setRecognitionRequests] = useState<RecognitionRequest[]>([]);

  const [filters, setFilters] = useState<{
    employee_email: string,
    status: string,
    manager: string,
  }>({ employee_email: "", status: "", manager: "" });
  
  // Extract unique statuses & managers
  const recognitionStatuses = ["Pending",  "Approved", "Rejected"];

  const [error, setError] = useState("");

  // Handle filter changes
  const handleFilterChange = (key:string, value:string) => {
    setFilters({...filters, [key]:value});

  };

  const [filteredData, setFilteredData] = useState(recognitionRequests); // Start with all data

  const handleGetManagerRecognitions = async () => {
    
    const recognitions = await RecognitionsApis.getManagerRecognitions().then((recognitions) => recognitions);

    setRecognitionRequests(recognitions);

  };


  useEffect( () => {
    handleGetManagerRecognitions();
  }, []);

  useEffect(() => {
    const isFilterActive = filters.employee_email || filters.status || filters.manager; // Check if any filter is applied

    if (!isFilterActive) {
      setFilteredData(recognitionRequests); // Reset to original data
      return;
    }

    const newFilteredData = recognitionRequests.filter(item =>
      (!filters.employee_email || item.employee === filters.employee_email) &&
    (!filters.status || item.status === filters.status)&&
    (!filters.manager || item.manager === filters.manager)
    );

    setFilteredData(newFilteredData);

  }, [filters, recognitionRequests]); // Re-run when filters or data change

  // Function to handle status update
  const handleStatusChange = async (event:any, row: Employee) => {
    const newStatus = event.target.value;

    try {
      // Simulate API call (replace with actual API request)
      
      RecognitionsApis.updateRecognitionStatus({
        id:row.id,
        status: newStatus,
      
      
      });

      setFilteredData((filteredData) =>
        filteredData.map((item) =>
          item.id === row.id ? { ...item, status: newStatus } : item
        )
      );

      setRecognitionRequests((data) =>
        data.map((item) =>
          item.id === row.id ? { ...item, status: newStatus } : item
        )
      );
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  return (
    <Box >
      {error && <Alert severity="error">{error}</Alert>}

      {/* Filters */}
      <Box display="flex" gap={2} mb={2}>

        <FormControl fullWidth variant="outlined">
          { <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={managerEmployees }
            getOptionLabel={(option: any) => option.name} // Use "name" instead of "label"
            value={employees.find(emp => emp.email === filters.employee_email) || null} // Match value to state
            onChange={(event, newValue) => handleFilterChange("employee_email", newValue ? newValue.email : "") }
            renderInput={(params) => 
              <TextField
                {...params}
                label="Employee Name"
                variant="outlined"
              />
            }
          />
          }
        </FormControl>
        
        {/* Status Filter */}
        <FormControl fullWidth variant="outlined">
          <InputLabel>Status</InputLabel>
          <Select
            value={filters.status}
            onChange={(e) => handleFilterChange("status", e.target.value)}
            label="Status"
          >
            <MenuItem value="">All</MenuItem>
            {recognitionStatuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <RecognitionTable employees={employees} filteredData={filteredData} filterKey={JSON.stringify(filters)} editable={false} handleStatusChange={handleStatusChange}/>

     
    </Box>
  );
};
export default ManagerRecognitions;
