import React, { useState } from "react";
import { 
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon, 
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Typography,
  Box,
  Chip,
  CircularProgress,
  Divider
} from "@mui/material";
import {
  InsertDriveFile as FileIcon,
  PictureAsPdf as PdfIcon,
  Image as ImageIcon,
  Description as DocIcon,
  Download as DownloadIcon
} from "@mui/icons-material";
import { Attachment } from "../../types/Idea";

interface AttachmentListProps {
  attachments: Attachment[];
}

const AttachmentList: React.FC<AttachmentListProps> = ({ 
  attachments, 
}) => {
  
  // Function to get the appropriate icon based on file type
  const getFileIcon = (fileType: string) => {
    if (fileType.includes("pdf")) {
      return <PdfIcon color="error" />;
    } else if (fileType.includes("image")) {
      return <ImageIcon color="primary" />;
    } else if (fileType.includes("word") || fileType.includes("document")) {
      return <DocIcon color="primary" />;
    } else {
      return <FileIcon />;
    }
  };
  
  // Function to handle downloading for images
  const handleDownload = async (attachment: Attachment) => {
    try {
      // For images, use the programmatic download approach to ensure
      // they're downloaded rather than opened in a new tab
      if (attachment.file_type.includes("image")) {
        const response = await fetch(attachment.file_url);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        
        const a = document.createElement("a");
        a.href = url;
        a.download = attachment.filename;
        document.body.appendChild(a);
        a.click();
        
        // Clean up
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 100);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  
  if (attachments.length === 0) {
    return (
      <Box sx={{ py: 2, textAlign: "center" }}>
        <Typography variant="body2" color="text.secondary">
          No attachments available
        </Typography>
      </Box>
    );
  }
  
  return (
    <List dense>
      {attachments.map((attachment, index) => (
        <React.Fragment key={attachment.id}>
          {index > 0 && <Divider component="li" />}
          <ListItem>
            <ListItemIcon>
              {getFileIcon(attachment.file_type)}
            </ListItemIcon>
            <ListItemText
              primary={attachment.filename}
              secondary={
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Chip
                    label={attachment.file_size_formatted}
                    size="small"
                    variant="outlined"
                  />
                  <Typography variant="caption" color="text.secondary">
                    {new Date(attachment.upload_date).toLocaleString()}
                  </Typography>
                </Box>
              }
            />
            <ListItemSecondaryAction>
              {attachment.file_type.includes("image") ? (
                // For images, use the programmatic download
                <Tooltip title="Download">
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() => handleDownload(attachment)}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                // For non-image files, use the download attribute
                <Tooltip title="Download">
                  <IconButton
                    edge="end"
                    aria-label="download"
                    component="a"
                    href={attachment.file_url}
                    download={attachment.filename}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  );
};

export default AttachmentList;