import axios from "axios";

const UsersApis = {
  signIn :(
    username: string,
    password: string,
  ) => {
    return (
      axios.post("api/auth/", {username: username, password: password}).then(
        result => {
          return result && result.data;
        }
      ).catch((e)=>{
        console.log("Something went wrong");
      })
    );
  },
};

export default UsersApis;