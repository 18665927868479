import { SET_RECOGNITIONS, SET_RECOGNITIONS_CATEGORIES} from "../actions/recognitions/types";


const initialState = {
  recognitions:[],
  recognitionsCategories:[]

};

const recognitionsReducer = (state = initialState, action: any): any => {
  switch (action.type) {
  case SET_RECOGNITIONS:
    return { ...state, recognitions: action.payload.recognitions };
  case SET_RECOGNITIONS_CATEGORIES:
    return { ...state, recognitionsCategories: action.payload.recognitionsCategories };
  default:
    return state;
  }
};

export { initialState, recognitionsReducer };