import {
  SET_USER_OBJ,
  UserObject,
  CLEAR_USER_OBJ,
} from "./types";

export const setUserObj = (user: UserObject) => (dispatch: any) => {
  dispatch({
    type: SET_USER_OBJ,
    payload: { user }
  });
};

export const clearUserObj = () => (dispatch: any) => {
  dispatch({
    type: CLEAR_USER_OBJ,
    payload: {}
  });
};
