import { makeStyles } from "@mui/styles";
import theme from "../../Theme";

export const useLoginStyles = makeStyles(() => ({
  paperWrapperStyle: {
    width: "350px",
    height: "550px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.3)",
    background: "#fff",
    borderRadius: 30,
    padding: "56px 70px 60px",
    [theme.breakpoints.up("md")]: {
      width: "479px",
      height: "600px",
    }
  },
  containerStyle: {
    height: "100vh",
    display:"flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: " linear-gradient(180deg, rgba(253, 190, 87, 0.95) 0%, rgba(253, 190, 87, 0.38) 100%)"
  },
  subTitleTextStyle: {
    fontFamily: "RobotoRegular",
    marginTop: 8,
    marginBottom: 16,
    alignSelf: "flex-start",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "22px"
  },
  gridItemStyle: {
    display: "flex",
    justifyContent: "center"
  },
  gridItemSmDownStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  logoStyle: {
    marginTop: 28,
    maxHeight: 40,
    maxWidth: 170
  },
  paperWrapperSmDownStyle: {
    borderRadius: 16
  },
  noAccountTextStyle: {
    marginRight: 5,
    height: 26,
    fontFamily: "RobotoRegular",
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: "22px"
  },
  signUpLink: {
    fontFamily: "RobotoRegular",
    textTransform: "none",
    color: "#0880AE",
    fontSize: 16,
    marginBottom: 28
  },
  logoGridContainer: {
    width: "100%",
    paddingLeft: 49,
    justifyContent: "flex-start",
  }
}));
