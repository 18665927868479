import {RecognitionRequest, RecognitionCategory} from "../../../types/types";

export const SET_RECOGNITIONS = "SET_RECOGNITIONS";
export const SET_RECOGNITIONS_CATEGORIES = "SET_RECOGNITIONS_CATEGORIES";


export interface setRecognitionsAction {
  type: string;
  payload: {recognitions: RecognitionRequest[]};
}

export interface setRecognitionsCategoriesAction {
  type: string;
  payload: {recognitionsCategories: RecognitionCategory[]};
}


export type RecognitionsActions = setRecognitionsAction | setRecognitionsCategoriesAction
