import { createTheme } from "@mui/material";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffd100",
      light: "rgba(255,209,0,0.52)"
    },
    secondary: {
      main: "#375E98",
      light: "#516A8F",
    },
    text: {
      primary: "#375E98",
      secondary: "#86848B"
    },
    background: {
      default: "#F5F5F5",
    },
  },
  zIndex: {
    snackbar: 1000,
    modal: 1200,
    drawer: 1100
  },
  breakpoints: {
    values: {
      xs: 0,  //mobile
      sm: 668, //tablet
      md: 1024,
      lg: 1400,
      xl: 1537, //laptop
    },
  },
});

export default theme;