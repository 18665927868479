import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import clsx from "clsx";
import { Button, InputAdornment, Typography } from "@mui/material";
import { useLoginFormStyles } from "./loginForm.style";
import FormikTextField from "./FormikTextfield/formikTextfield";
import UsersApi from "../../apis/UsersApi";
import AsalLogo from "../../images/new-logo.png";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserObj } from "../../redux/actions/userObject";
import { setUserDetails } from "../../utils";

export const LoginForm = () => {
  const classes = useLoginFormStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    form,
    formControlStyle,
    inputFieldErroredStyle,
    inputFieldHighlightedStyle,
    inputFieldStyle,
    submit,
    asalLogo,
    loginFormContainer,
  } = classes;
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter a valid email"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = (values: any): void => {
    const userObj: any = {
      username: values.email,
      password: values.password,
    };
    UsersApi.signIn(userObj.username, userObj.password).then(
      (authResult: any) => {
        const { key, user } = authResult;
        sessionStorage.setItem("access_token", key);
        setUserDetails(user);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(setUserObj(user));
        navigate("/");
      }
    );
  };

  return (
    <div className={loginFormContainer}>
      <img src={AsalLogo} className={asalLogo} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          errors,
        }: FormikProps<any>): React.ReactNode => {
          const emailInputFieldClasses = clsx(
            inputFieldStyle,
            values.email && inputFieldHighlightedStyle,
            errors.email && inputFieldErroredStyle
          );
          const passwordInputFieldClasses = clsx(
            inputFieldStyle,
            values.password && inputFieldHighlightedStyle,
            errors.password && inputFieldErroredStyle
          );
          return (
            <Form className={form}>
              <FormikTextField
                FormControlStyle={formControlStyle}
                id={"email"}
                name={"email"}
                label={"email"}
                value={values.email}
                autoFocus
                fullWidth
                autoComplete={"email"}
                margin={"normal"}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                error={errors["email"]}
                className={emailInputFieldClasses}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormikTextField
                FormControlStyle={formControlStyle}
                id={"password"}
                name={"password"}
                type={"password"}
                label={"password"}
                value={values.password}
                fullWidth
                margin={"normal"}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                error={errors["password"]}
                className={passwordInputFieldClasses}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={submit}
              >
                Log in
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
