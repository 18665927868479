import {Employee } from "../../../types/types";

export const SET_EMPLOYEES = "SET_EMPLOYEES";


export interface setEmployeesAction {
  type: string;
  payload: {employees: Employee[]};
}


export type EmployeesActions = setEmployeesAction
