import "./App.css";
import { Box, CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import RouterComponent from "./routes/Router";
import { UpperBar } from "./components/UpperBar/UpperBar";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";
import AxiosInterceptor from "./apis/AxiosInterceptor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalStyle } from "./global.style";


function App() {

  const classes = useGlobalStyle();
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AxiosInterceptor/>
        <BrowserRouter>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <CssBaseline />
          <Box className={classes.Content}>
            <RouterComponent/>
          </Box>
        </BrowserRouter>
      </ThemeProvider>

    </Provider>
  );
}

export default App;
