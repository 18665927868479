import {
  Typography,
  Box,
} from "@mui/material";
import { useRecognitionsStyles } from "./Recognitions.style";
import { UpperBar } from "../../components/UpperBar/UpperBar";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { setRecognitionsCategoriesAction } from "../../redux/actions/recognitions/actions";
import { setEmployeesAction } from "../../redux/actions/employees/actions";
import RecognitionsApis from "../../apis/RecognitionsApi";
import { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Employee, RecognitionRequest, BudgetStats } from "../../types/types";

import {Backdrop, CircularProgress} from "@mui/material";
import {Recognitions} from "./Recognitions";
import RecognitionsCategories from "./RecognitionsCategories";
import {ManagersReport} from "./ManagersReport";


const Loader = () => (
  <Backdrop open>
    <CircularProgress color="inherit" />
  </Backdrop>
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function HRDashboard() {
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [managers, setManagers] = useState([]);

  const employees = useSelector(
    (state: RootState) => state.employees.employees
  );

  const recognitionsCategories = useSelector(
    (state: RootState) => state.recognitions.recognitionsCategories
  );

  const [recognitionRequests, setRecognitionRequests] = useState<RecognitionRequest[]>([]);

  const [managersBudget, setManagersBudget] = useState<BudgetStats[]>();

  useEffect(() => {
    // setLoading(true);
    // Promise.all([handleGetEmployees(), getRecognitionCategories(), handleGetManagerRecognitions(), handleGetManagers()]).then(() => {
    //   setLoading(false);
    // });


    const fetchData = async () => {
      setLoading(true);
      let employeesData = employees;
  
      try {
        if(!employees) {
          // First, fetch employees
          employeesData = await handleGetEmployees();
        }

        if(!managers || managers.length ==0) {
          // Then, fetch managers (since it depends on employees)
          await handleGetManagers(employeesData);
        }

        // Meanwhile, fetch categories and manager recognitions in parallel
        await Promise.all([getRecognitionCategories(), handleGetManagerRecognitions(), getManagersStatistics()]);
  
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
  
    fetchData();


  }, []);


  

  const getManagersStatistics = async () => {
    
    const managersData = await RecognitionsApis.getManagersStatistics().then((data) => data);
    if (managersData) {
      
      setManagersBudget(managersData);

    }
  };

  const handleGetEmployees = async () => {
    
    const employeesData = await RecognitionsApis.getEmployees().then((employees) => employees);
    if (employeesData) {

      dispatch(setEmployeesAction(employeesData));
   
      return employeesData;
    }
  };

  const handleGetManagers = async (employees:Employee[]) => {
    const managersEmailList = await RecognitionsApis.getManagers().then((managers) => managers);
    if (managersEmailList && employees) {
      const filteredManagers = employees.filter(emp => managersEmailList.includes(emp.email));

      setManagers(filteredManagers);
    }
  };

  const getRecognitionCategories = async () => {
    const recognitionCategoriesData = await RecognitionsApis.getRecognitionCategories().then((categories) => categories);

    if (recognitionCategoriesData) {
      dispatch(setRecognitionsCategoriesAction(recognitionCategoriesData));
    }
    
  };

  const handleGetManagerRecognitions = async () => {
    
    const recognitions = await RecognitionsApis.getManagerRecognitions().then((recognitions) => recognitions);

    setRecognitionRequests(recognitions);

  };


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const classes = useRecognitionsStyles();
  const {
    root,
  } = classes;

  return (
    
    <Box className={root}>
      <UpperBar bgColor={"secondary"}/>

      <div style={{ padding: "20px" }}>
        {!loading ? <>
          {/* Filters */}
          <Box display="flex" gap={2} mb={2} sx={{ height: "100%", minHeight: "300px" }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                flexShrink: 0,
                minWidth: "150px",
              }}
            >

              <Tab
                disabled
                label={
                  <Typography
                    variant="h4"
                    component="h3"
                    fontWeight="medium"
                    sx={{
                      textTransform: "none",
                      color: "#375E99",
                      cursor: "default",
                    }}
                  >
                      HR Dashboard
                  </Typography>
                }
                sx={{
                  "&.Mui-disabled": {
                    opacity: 1,
                    color: "#375E99",
                  },
                }}
                aria-hidden="true"
              />
                
              <Tab label="Recognitions" {...a11yProps(0)} />
              <Tab label="Manage Categories" {...a11yProps(1)} />
              <Tab label="Reports" {...a11yProps(2)} />

            </Tabs>
            <TabPanel value={value === 0 ? 1 : value} index={1}>
              <Recognitions employees={employees} managers={managers}  recognitionRequests={recognitionRequests} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <RecognitionsCategories recognitionsCategories={recognitionsCategories} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ManagersReport managersBudget={managersBudget} employees={employees} />
            </TabPanel>
   
          </Box>
        </> : <Loader />}
      </div>
    </Box>
  
  );
}

