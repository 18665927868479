import { makeStyles } from "@mui/styles";
import theme from "../../Theme";
import headerBg from "../../images/header_bg_big.jpg";

export const useHeaderStyle = makeStyles(() => ({
  boxStyle: {
    height: "380px",
    [theme.breakpoints.down("md")]: {
      height: "210px",
    },
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    backgroundImage: `url(${headerBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  headerTitle: {
    color: `${theme.palette.text.primary}`,
    fontSize: "40px !important",
    fontWeight: "700 !important",
  },
}));
