import { Box, Typography } from "@mui/material";
import { Link } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useCategoriesStyles } from "../Categories/categories.style";


const OperationsCard = () => {

  const classes = useCategoriesStyles();
  const { categoryCard, name, desc} = classes;

  return (
  
    <Link href= "./ideas" sx={{ textDecoration: "none" }}>
      <Box className={categoryCard}>
        <div >
          <Typography className={name} >Operations</Typography>
          <Typography className={desc}>Operations Dashboard</Typography>
        </div>
      </Box>
    </Link>

  );
};

export default OperationsCard;
