// Importing helper modules
import {useCallback, useEffect, useMemo, useRef, useState} from "react";

// Importing core components
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import QuillEditor from "react-quill";

// Importing styles
import "react-quill/dist/quill.snow.css";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Editor = ({ defaultValue, onChange, setEditor }) => {
  // Editor state
  const [value, setValue] = useState(defaultValue);

  // Editor ref
  const quill = useRef();

  useEffect(()=>{
    setEditor(quill.current);
  },
  [quill.current]);
  
  // Handler to handle button clicked
  function handleChange(value: string) {
    setValue(value);
    onChange(value);
  }

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files && input.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const imageUrl = reader.result;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const quillEditor = quill?.current?.getEditor();

        const range = quillEditor.getSelection(true);
        quillEditor.insertEmbed(range.index, "image", imageUrl);
      };

      file && reader.readAsDataURL(file);
    };
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
          [{ "direction" : "rtl"}],
          [{ align: "" }, { align: "center" }, { align: "right" }]
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    [imageHandler],
  );

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "clean",
    "direction",
    "align"
  ];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    <div>
      <QuillEditor
        ref={(el: any) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          quill.current = el;
        }}
        theme="snow"
        value={value}
        formats={formats}
        modules={modules}
        onChange={(value: any) => handleChange(value)}
      />
    </div>
  );
};

export default Editor;