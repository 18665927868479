import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { userObjReducer } from "./reducers/userObjReducer";
import { categoriesReducer } from "./reducers/categories";
import {employeesReducer} from "./reducers/employees";
import {recognitionsReducer} from "./reducers/recognitions";

const rootReducer = combineReducers({
  userObj: userObjReducer,
  categories: categoriesReducer,
  employees: employeesReducer,
  recognitions: recognitionsReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));
export type RootState = ReturnType<typeof rootReducer>;
export default store;
