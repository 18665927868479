import { makeStyles } from "@mui/styles";

export const useRecognitionsStyles = makeStyles(() => ({
  root: {
    padding: 90,
    display: "flex",
    flexDirection: "column",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "white",
    },
  },
}));
