import {
  Typography,
  Box,
} from "@mui/material";
import { useRecognitionsStyles } from "./Recognitions.style";
import ManagerNewRecognitionRequest from "./ManagerNewRecognitionRequest";
import { UpperBar } from "../../components/UpperBar/UpperBar";
import ManagerRecognitions from "./ManagerRecognitions";
import ManagerReport from "./ManagerReport";

import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { setRecognitionsCategoriesAction } from "../../redux/actions/recognitions/actions";
import { setEmployeesAction } from "../../redux/actions/employees/actions";
import RecognitionsApis from "../../apis/RecognitionsApi";
import { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

import {Backdrop, CircularProgress} from "@mui/material";
import { BudgetStats, Employee } from "../../types/types";


const Loader = () => (
  <Backdrop open>
    <CircularProgress color="inherit" />
  </Backdrop>
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function ManagerDashboard() {
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(true);

  const { userDetails } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
  }));

  const employees = useSelector(
    (state: RootState) => state.employees.employees
  );

  const recognitionsCategories = useSelector(
    (state: RootState) => state.recognitions.recognitionsCategories
  );

  const [managerBudget, setManagerBudget] = useState<BudgetStats>();



  useEffect(() => {
    setLoading(true);
    Promise.all([handleGetEmployees(), getRecognitionCategories(), handleGetStatistics()]).then(() => {
      setLoading(false);
    });

  }, []);

  const handleGetEmployees = async () => {
    
    const employees = await RecognitionsApis.getEmployees().then((employees) => employees);
    if (employees) {
      //setEmployees(employees);
      dispatch(setEmployeesAction(employees));
    }
  };

  const handleGetStatistics = async () => {
    
    const data = await RecognitionsApis.getManagerStatistics(userDetails.email).then((data) => data);
    if (data) {
      setManagerBudget(data);
    }
  };

  const getRecognitionCategories = async () => {
    const recognitionCategories = await RecognitionsApis.getRecognitionCategories().then((categories) => categories);

    if (recognitionCategories) {
      dispatch(setRecognitionsCategoriesAction(recognitionCategories));
    }
  };


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const classes = useRecognitionsStyles();
  const {
    root,
  } = classes;

  return (
    
    <Box className={root}>
      <UpperBar bgColor={"secondary"}/>

      <div style={{ padding: "20px" }}>
        {!loading ? <>
          {/* Filters */}
          <Box display="flex" gap={2} mb={2} sx={{ height: "100%", minHeight: "300px" }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                flexShrink: 0,
                minWidth: "150px",
              }}
            >
              <Tab
                disabled
                label={
                  <Typography
                    variant="h4"
                    component="h3"
                    fontWeight="medium"
                    sx={{
                      textTransform: "none",
                      color: "#375E99",
                      cursor: "default",
                    }}
                  >
                      Manager Dashboard
                  </Typography>
                }
                sx={{
                  "&.Mui-disabled": {
                    opacity: 1,
                    color: "#375E99",
                  },
                }}
                aria-hidden="true"
              />
              <Tab label="New Request" {...a11yProps(0)} />
              <Tab label="My Requests" {...a11yProps(1)} />
              <Tab label="Statistics" {...a11yProps(1)} />

            </Tabs>
            <Box sx={{ width: "90%", maxWidth: "1000px", ml: 0 }}>
              <TabPanel value={value === 0 ? 1 : value} index={1} >
                <ManagerNewRecognitionRequest employees={employees.filter((emp: Employee) => emp.manager_email?.toLowerCase() === userDetails.email?.toLowerCase())} recognitionsCategories={recognitionsCategories} onSubmitSuccess={handleGetStatistics} isLoading={loading}/>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ManagerRecognitions  employees={employees}   />
              </TabPanel>
              
              <TabPanel value={value} index={3}>
                <ManagerReport managerBudget={managerBudget} />
              </TabPanel>
            </Box>
          </Box>
        </> : <Loader />}
      </div>
    </Box>
    
  );
}

