import { 
  TableContainer, 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Paper,
  Box,
  Typography
} from "@mui/material";
import { Employee } from "../../types/types";


export const ManagersReport = ({managersBudget, employees}: any) => {
  return (
    <Box sx={{ minHeight: "100vh", bgcolor: "grey.100", p: 4 }}>
      <Box sx={{ maxWidth: 1200, mx: "auto" }}>
        <Paper elevation={2}>
          <Box sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ textAlign: "center", mb: 3, fontWeight: "bold" }}>
              Managers Budget Overview
            </Typography>
            
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Manager</TableCell>
                    <TableCell align="right">Employees</TableCell>
                    <TableCell align="right">Total Budget</TableCell>
                    <TableCell align="right">Used Budget</TableCell>
                    <TableCell align="right">Remaining</TableCell>
                    <TableCell align="right">Utilization</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {managersBudget && managersBudget.map((manager, index) => {
                    const utilization = (manager.usedBudget / manager.totalBudget) * 100;
                    return (
                      <TableRow
                        key={manager.name}
                        sx={{ "&:nth-of-type(odd)": { bgcolor: "grey.50" } }}
                      >
                       
                        <TableCell sx={{ fontWeight: 500 }}>{employees.find((emp:Employee) => emp.email === manager.manager_email)?.name || null} </TableCell>

                        <TableCell align="right">
                          {manager.numberOfEmployees}
                        </TableCell>
                        <TableCell align="right">
                          {manager.totalBudget}
                        </TableCell>
                        <TableCell align="right">
                          {manager.usedBudget}
                        </TableCell>
                        <TableCell align="right">
                          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 1 }}>
                        
                            <Typography
                              component="span"
                              sx={{
                                color: manager.remainingBudget > 0 ? "success.main" : "error.main",
                                fontWeight: "bold"
                              }}
                            >
                              {manager.remainingBudget}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <Box
                            component="span"
                            sx={{
                              px: 1.5,
                              py: 0.5,
                              borderRadius: 1,
                              bgcolor: utilization > 90 ? "error.100" :
                                utilization > 70 ? "warning.100" :
                                  "success.100",
                              color: utilization > 90 ? "error.800" :
                                utilization > 70 ? "warning.800" :
                                  "success.800"
                            }}
                          >
                            {utilization.toFixed(1)}%
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default ManagersReport;