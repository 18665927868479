import { Box, Typography } from "@mui/material";
import { Link } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useCategoriesStyles } from "../Categories/categories.style";


const RecognitionCard = () => {

  const classes = useCategoriesStyles();
  const { root, categoryCard, name } = classes;

  const { userDetails } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
  }));

  return (
  
    <Box className={root}>
      {userDetails.is_recognition_receiver && <Link href= "./recognitions" sx={{ textDecoration: "none" }}>
        <Box
          className={categoryCard}
          key={"HR_Dashboard"}
          onClick={() => console.log("")}
        >
      
          <div>
            <Typography className={name}>{"Recognitions - HR Dashboard"}</Typography>
          </div>
        
        </Box>
      </Link>
      }
      {userDetails.is_recognition_requester  && <Link href= "./recognition-request" sx={{ textDecoration: "none" }}>
        <Box
          className={categoryCard}
          key={"Managers_Dashboard"}
          onClick={() => console.log("")}
        >
      
          <div>
            <Typography className={name}>{"Recognitions - Managers Dashboard"}</Typography>
          </div>
        </Box>
      </Link>
      }
    
    </Box>

  );
};

export default RecognitionCard;
