import axios from "axios";
import { toast } from "react-toastify";
import { Idea, CreateIdeaDto, UpdateIdeaStatusDto, Attachment } from "../types/Idea";

const IdeasApi = {
  getIdeas:(params?: { 
    idea_type?: string; 
    potential_impact?: string;
    search?: string;
    ordering?: string;
    page?: number;
  }) => {
    return (
      axios.get("api/ideas/", { params }).then(
        (result: { data: any; }) => {
          return result && result.data;
        }
      ).catch(()=>{
        console.log("Something went wrong");
      })
    );
  },

  // Create a new idea
  createIdea: async (ideaData: CreateIdeaDto): Promise<Idea> => {
    try {
      // Handle file upload if attachment exists
      if (ideaData.attachment) {
        const formData = new FormData();
          
        // Add all non-file fields to formData
        Object.entries(ideaData).forEach(([key, value]) => {
          if (key !== "attachment" && value !== undefined && value !== null) {
            formData.append(key, value.toString());
          }
        });
          
        // Add file to formData
        formData.append("attachment", ideaData.attachment);
          
        const response = await axios.post("api/ideas/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return response.data;
      } else {
        // Regular JSON request if no attachment
        const response = await axios.post("api/ideas/", ideaData);
        return response.data;
      }
    } catch (error) {
      console.error("Error creating idea:", error);
      throw error;
    }
  },

  updateIdeaStatus: async (statusData: UpdateIdeaStatusDto): Promise<Idea> => {
    try {
      const { id, status } = statusData;
      const response = await axios.patch(`api/ideas/${id}/status/`, { status });
      return response.data;
    } catch (error) {
      console.error(`Error updating status for idea ID ${statusData.id}:`, error);
      throw error;
    }
  },

  getIdeaById: async (id: number): Promise<Idea> => {
    try {
      const response = await axios.get(`api/ideas/${id}/`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching idea with ID ${id}:`, error);
      throw error;
    }
  },

  exportIdeas:() => {
    return (
      axios.get("api/ideas/export/", {
        responseType: "blob",
        withCredentials: true,
      }).then(
        (result: { data: any; }) => {
          return result;
        }
      ).catch(()=>{
        console.log("Something went wrong");
      })
    );
  },

  getAttachments: async (ideaId: number): Promise<Attachment[]> => {
    try {
      const response = await axios.get(`/api/ideas/${ideaId}/attachments/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching attachments:", error);
      throw error;
    }
  },

  uploadAttachments: async (ideaId: number, files: File[]): Promise<Attachment[]> => {
    try {
      const formData = new FormData();
      
      // Append each file to the form data
      files.forEach((file) => {
        formData.append("files", file);
      });
      
      // Get the CSRF token from cookie
      const getCsrfToken = () => {
        return document.cookie
          .split("; ")
          .find(row => row.startsWith("csrftoken="))
          ?.split("=")[1];
      };

      const csrfToken = getCsrfToken();

      
      const token = sessionStorage.getItem("access_token");
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/ideas/${ideaId}/attachments/`, {
        method: "POST",
        body: formData,
        credentials: "include",
        headers: {
          "Authorization": "Token " + token,
          // No Content-Type header
          "X-CSRFToken": csrfToken, 
        }
      });
      
      if (!response.ok) {
        throw new Error(`Upload failed: ${response.status}`);
      }
      
      return response.data;
    } catch (error) {
      console.error("Error uploading attachments:", error);
      throw error;
    }
  },

  deleteAttachment: async (ideaId: number, attachmentId: number): Promise<void> => {
    try {
      await axios.delete(`/api/ideas/${ideaId}/attachments/${attachmentId}/`, {
        withCredentials: true,
      });
    } catch (error) {
      console.error(`Error deleting attachment with ID ${attachmentId}:`, error);
      throw error;
    }
  },
 

};
export default IdeasApi;