import axios from "axios";
import { toast } from "react-toastify";
import {  RecognitionCategory, RecognitionRequest } from "../types/types";

const RecognitionsApi = {
  getEmployees:(
  ) => {
    return (
      axios.get("api/recognitions/employees/").then(
        (result: { data: any; }) => {
          return result && result.data;
        }
      ).catch(()=>{
        console.log("Something went wrong");
      })
    );
  },
  getRecognitionCategories:(
  ) => {
    return (
      axios.get("api/recognitions/categories/").then(
        (result: { data: any; }) => {
          return result && result.data;
        }
      ).catch(()=>{
        console.log("Something went wrong");
      })
    );
  },

  addNewRequest:(payload: Partial<RecognitionRequest>) :Promise<RecognitionRequest> =>  {
    return (
      axios.post("api/recognitions/requests/", payload).then(
        result =>  {
          toast.success("Request Sent Successfully", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          return result && result.data;
        }
      ).catch((error)=>{
        if (error.response && error.response.status === 400) {
          toast.error("Bad Request: " + (error.response.data[0] || "Unable to send recognition request"), {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        } else {
          toast.error("Unable to send a recognition request", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
    );
  },
  getAllRequests:(
  ) => {
    return (
      axios.get("api/recognitions/requests/").then(
        (result: { data: any; }) => {
          return result && result.data;
        }
      ).catch(()=>{
        console.log("Something went wrong");
      })
    );
  },
  updateRecognitionStatus:(payload: Partial<RecognitionRequest>) :Promise<RecognitionRequest> =>  {
    return (
      axios.put(`api/recognitions/requests/${payload.id}/`, payload).then(
        result =>  {
          toast.success("Status updated Successfully", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          return result && result.data;
        }
      ).catch(()=>{
        toast.error("Unable To update status", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
    );
  },

  updateRecognitionCategoryVisibility:(payload: Partial<RecognitionCategory>) :Promise<RecognitionCategory> =>  {
    return (
      axios.put(`api/recognitions/categories/${payload.id}/`, payload).then(
        result =>  {
          toast.success("Status updated Successfully", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          return result && result.data;
        }
      ).catch(()=>{
        toast.error("Unable To update status", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
    );
  },
  addNewRecognitionCategory:(payload: Partial<RecognitionCategory>) :Promise<RecognitionCategory> =>  {
    return (
      axios.post("api/recognitions/categories/", payload).then(
        result =>  {
          toast.success("Category Sent Successfully", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          return result && result.data;
        }
      ).catch((error)=>{
        if (error.response && error.response.status === 400) {
          toast.error("Bad Request: " + (error.response.data["non_field_errors"] || "Unable to create a new category 1"), {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        } else {
          toast.error("Unable to create a new category", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
        throw error;
      })
    );
  },
  getManagerRecognitions:(
  ) => {
    return (
      axios.get("api/recognitions/myrecognitions/").then(
        (result: { data: any; }) => {
          return result && result.data;
        }
      ).catch(()=>{
        console.log("Something went wrong");
      })
    );
  },
  getManagers:(
  ) => {
    return (
      axios.get("api/recognitions/managers/").then(
        (result: { data: any; }) => {
          return result && result.data;
        }
      ).catch(()=>{
        console.log("Something went wrong");
      })
    );
  },
  getManagerStatistics:(manager_email: string) => {
    return (
      axios.get(`api/recognitions/manager-budget/${manager_email}/`).then(
        (result: { data: any; }) => {
          return result && result.data;
        }
      ).catch(()=>{
        console.log("Something went wrong");
      })
    );
  },

  getManagersStatistics:() => {
    return (
      axios.get("api/recognitions/managers-budget/").then(
        (result: { data: any; }) => {
          return result && result.data;
        }
      ).catch(()=>{
        console.log("Something went wrong");
      })
    );
  },
};
export default RecognitionsApi;